import React from "react";
import { Route, Routes } from "react-router-dom";
// import GoogleCallback from "../components/google/GoogleCallback";
import GuestPage from "../components/guest";
// import GenerateQRWLogo from "../components/guest/GenerateQRWLogo";
import GuestAppbar from "../layouts/GuestAppbar";
import GuestSignupPage from "../components/guest/MerchantSignup";

const GuestRoute = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <React.Fragment>
        <GuestAppbar />
        <Routes>
          <Route hidden path="/" element={<GuestPage />} />
          <Route hidden path="/sign-up" element={<GuestSignupPage />} />
          {/* <Route hidden path="/esther/nehemiah/solutions/generate/qr-with-logo" element={<GenerateQRWLogo />} /> */}
          {/* <Route path="/auth/google" element={<GoogleCallback />} /> */}
        </Routes>
      </React.Fragment>
    </div>
  );
};

export default GuestRoute;
