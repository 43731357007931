import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageViewer from "react-simple-image-viewer";

const ProprietorPayments = ({ allowPayment, module }) => {
  const [paymentList, setPaymentList] = useState({ data: [], ready: false });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMerchantPayments = () => {
    axios
      .get("user/admin/get/proprietor/payments")
      .then((response) => {
        const data = response.data;
        setPaymentList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    getMerchantPayments();
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Proprietor Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Added Limit</b>
              </TableCell>
              <TableCell align="center">
                <b>Amount</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentList.ready && paymentList.data.length > 0 ? (
              paymentList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {data.proprietorpaymentproprietorinfo.firstname}{" "}
                    {data.proprietorpaymentproprietorinfo.lastname}
                  </TableCell>
                  <TableCell align="right">{data.new_branch_limit}</TableCell>
                  <TableCell align="right">
                    &#8369;
                    {parseFloat(data.amount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="View Attachment">
                        <AttachFileIcon
                          sx={{ cursor: "pointer", mr: 1 }}
                          onClick={() => {
                            let dataArr = data.image.split(",");
                            const res = dataArr.filter(
                              (item) =>
                                item.includes(".jpg") ||
                                item.includes(".png") ||
                                item.includes(".jpeg") ||
                                item.includes(".JPG") ||
                                item.includes(".PNG") ||
                                item.includes(".JPEG")
                            );
                            setSelectedImages(
                              res.map(
                                (y) =>
                                  `${process.env.REACT_APP_API_IMAGE}proprietor/payment/` +
                                  y
                              )
                            );
                            setIsViewerOpen(true);
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} style={{ color: "red" }}>
                  No proprietor payment added
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={paymentList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {isViewerOpen && (
        <ImageViewer
          src={selectedImages}
          currentIndex={0}
          onClose={() => setIsViewerOpen(false)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Box>
  );
};

export default ProprietorPayments;
