import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import axios from "axios";
import { DropzoneArea } from "react-mui-dropzone";
import { useState } from "react";
import { useContext } from "react";
import { UserInfo } from "../../context";
import { useNavigate } from "react-router-dom";

const MerchantConfirmDocu = () => {
  const url = window.location.search;
  const urlClient = new URLSearchParams(url).get("id");
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const navigate = useNavigate();

  const [validID, setValidID] = useState([]);
  const [permitImg, setPermitImg] = useState([]);
  const [menuImg, setMenuImg] = useState([]);

  const handleImageChangeNew = (files, updater) => {
    updater(files);
  };

  const handleNewAttachments = (e) => {
    e.persist();
    e.preventDefault();

    var formdata = new FormData(e.target);
    formdata.append("merchant_id", urlClient);
    formdata.append("valid_id", validID[0]);
    formdata.append("permit", permitImg[0]);
    formdata.append("menu", menuImg[0]);

    var err = [];
    if (menuImg.length < 1) {
      err = "error";
      setMessage("Menu is required");
      setSeverity("error");
      setOpenSnackbar(true);
    }
    if (permitImg.length < 1) {
      err = "error";
      setMessage("Permit is required");
      setSeverity("error");
      setOpenSnackbar(true);
    }
    if (validID.length < 1) {
      err = "error";
      setMessage("Valid ID is required");
      setSeverity("error");
      setOpenSnackbar(true);
    }
    if (err.length > 0) {
      console.log("form has a problem!");
    } else {
      setIsProcess(true);
      axios
        .post("user/merchant/update/new-attachments", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setMessage(
              "Successfully update your attachments, We'll review now your attachments. You will redirect to the dashboard."
            );
            setSeverity("success");
            setTimeout(() => {
              setIsProcess(false);
              setOpenSnackbar(true);
              setTimeout(() => {
                navigate("/");
                navigate(0);
              }, 2000);
            }, 1000);
          }
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("error", error);
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100vw",
            maxWidth: "1165px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",

                mx: "15px",
              }}
            >
              <Box>
                <Typography variant="h5">Attachments for approval</Typography>
              </Box>
              <Box flexGrow={1} />
            </Box>
          </Box>

          <form onSubmit={handleNewAttachments} encType="multipart/form-data">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mx: "15px",
                }}
              >
                <DropzoneArea
                  onChange={(e) => handleImageChangeNew(e, setValidID)}
                  acceptedFiles={["image/jpeg", "image/png"]}
                  dropzoneText={
                    "Upload 1 valid ID (Drivers License, Passport, or Any Government Issue ID with picture)."
                  }
                  maxFileSize={2000000}
                  filesLimit={1}
                  dropzoneClass={"dropZoneCustom"}
                  dropzoneParagraphClass={"dropZoneCustomText"}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mx: "15px",
                }}
              >
                <DropzoneArea
                  onChange={(e) => handleImageChangeNew(e, setPermitImg)}
                  acceptedFiles={["image/jpeg", "image/png"]}
                  dropzoneText={
                    "Upload Business Permit or Barangay Certification."
                  }
                  maxFileSize={2000000}
                  filesLimit={1}
                  dropzoneClass={"dropZoneCustom"}
                  dropzoneParagraphClass={"dropZoneCustomText"}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mx: "15px",
                }}
              >
                <DropzoneArea
                  onChange={(e) => handleImageChangeNew(e, setMenuImg)}
                  acceptedFiles={["image/jpeg", "image/png"]}
                  dropzoneText={"Upload a picture of your current menu."}
                  maxFileSize={2000000}
                  filesLimit={1}
                  dropzoneClass={"dropZoneCustom"}
                  dropzoneParagraphClass={"dropZoneCustomText"}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box flexGrow={1} />
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 2,
                    fontSize: 12,
                    mx: "15px",
                    backgroundColor: "#261138",
                  }}
                  type="submit"
                  disabled={isProcess}
                >
                  Save Attachments
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default MerchantConfirmDocu;
