import {
  Bento,
  // CardGiftcard,
  // Help,
  ManageAccounts,
} from "@mui/icons-material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CustomerInfo, UserInfo } from "../components/context";
import CustomerPage from "../components/customer";
import CustomerHelpDesk from "../components/customer/helpcenter";
import MerchantsProducts from "../components/customer/merchant";
import CustomerCart from "../components/customer/mycart";
import CustomerOrders from "../components/customer/order";
import CustomerProfile from "../components/customer/profile";
import CustomerVouchers from "../components/customer/vouchers";
import GoogleCallback from "../components/google/GoogleCallback";
import CustomerAppbar from "../layouts/CustomerAppbar";

const CustomerRoute = ({ module }) => {
  const { userData } = useContext(UserInfo);
  const [userInfo, setUserInfo] = useState({ data: [], ready: false });
  const [userLocation, setUserLocation] = useState({ data: [], ready: false });
  const [cartOrder, setCartOrder] = useState({ data: [], ready: false });
  const [cartOrderQty, setCartOrderQty] = useState(0);
  const [subtotal, setSubtotal] = useState(0);

  //checkout
  const [checkoutOrder, setCheckoutOrder] = useState({
    data: [],
    ready: false,
  });
  const [checkoutOrderQty, setCheckoutOrderQty] = useState(0);
  const [subtotalCheckout, setSubtotalCheckout] = useState(0);

  const getUserInfo = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("get/user/info", { params })
      .then((response) => {
        const data = response.data;
        setUserInfo({ data, ready: true });
      })
      .catch((err) => console.log("err", err));
  };

  const getUserLocation = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("get/user/location-active", { params })
      .then((response) => {
        const data = response.data;
        setUserLocation({ data, ready: true });
      })
      .catch((err) => console.log("err", err));
  };

  const getOrderInCart = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("customer/get/cart/orders", { params })
      .then((response) => {
        const data = response.data;
        setCartOrder({ data, ready: true });
        setSubtotal(calculateTotal(data));
        if (data.length > 0) {
          setCartOrderQty(data.length);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const getOrderInCheckout = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("customer/get/checkout/orders", { params })
      .then((response) => {
        const data = response.data;
        setCheckoutOrder({ data, ready: true });
        setSubtotalCheckout(calculateTotal(data));
        if (data.length > 0) {
          setCheckoutOrderQty(data.length);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const calculateTotal = (cart) => {
    var total = 0;
    for (let i = 0; i < cart.length; i++) {
      total += parseInt(cart[i].quantity) * parseFloat(cart[i].price);
    }
    return total;
  };

  const routeArray = [
    {
      name: "Profile",
      path: "/profile",
      icon: <ManageAccounts fontSize="small" />,
    },
    {
      name: "Orders",
      path: "/orders",
      icon: <Bento fontSize="small" />,
    },
    // {
    //   name: "Help Center",
    //   path: "/help-center",
    //   icon: <Help fontSize="small" />,
    // },
  ];

  useEffect(() => {
    getUserInfo();
    getUserLocation();
    getOrderInCart();
    getOrderInCheckout();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <CustomerInfo.Provider
        value={{
          userInfo,
          setUserInfo,
          reRunInfo: () => getUserInfo(),

          userLocation,
          setUserLocation,
          reRunLocation: () => getUserLocation(),

          cartOrder,
          cartOrderQty,
          reRunCart: () => getOrderInCart(),
          subtotal,

          checkoutOrder,
          checkoutOrderQty,
          reRunCheckout: () => getOrderInCheckout(),
          subtotalCheckout,
        }}
      >
        <CustomerAppbar
          module={module}
          route={routeArray}
          cartOrder={cartOrder}
          checkoutOrder={checkoutOrder}
        />
        <Routes>
          <Route hidden path="/" element={<Navigate to="/customer" />} />
          <Route hidden path="/customer" element={<CustomerPage />} />
          <Route hidden path="/profile" element={<CustomerProfile />} />
          <Route hidden path="/vouchers" element={<CustomerVouchers />} />
          <Route hidden path="/help-center" element={<CustomerHelpDesk />} />
          <Route hidden path="/orders" element={<CustomerOrders />} />
          <Route hidden path="/customer/cart" element={<CustomerCart />} />
          <Route
            hidden
            path="/customer/merchant/:merchant_id"
            element={<MerchantsProducts />}
          />
          <Route path="/auth/google" element={<GoogleCallback />} />
        </Routes>
      </CustomerInfo.Provider>
    </React.Fragment>
  );
};

export default CustomerRoute;
