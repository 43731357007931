import {
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
// import axios from "axios";
import React, { useContext } from "react";
import { CustomerInfo, UserInfo } from "../../context";
import MopedIcon from "@mui/icons-material/Moped";
import axios from "axios";

const CheckoutOrder = () => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar, userData } =
    useContext(UserInfo);
  const {
    checkoutOrder,
    subtotalCheckout,
    reRunCheckout,
    // userInfo 
  } = useContext(CustomerInfo);

  const handleToCheckout = () => {
    var formdata = new FormData();
    formdata.append("customer_id", userData.data.id);
    setIsProcess(true);
    axios
      .post("customer/create/order-to-placed", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setIsProcess(false);
          setMessage("Successfully placed order.");
          setSeverity("success");
          setTimeout(() => {
            reRunCheckout();
            setOpenSnackbar(true);
          }, 500);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 700 }}
        >
          Merchant
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5">
          {checkoutOrder.data[0].business_name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
        }}
      >
        <Typography variant="subtitle2">
          {/* {checkoutOrder.data[0].business_address} */}
          {checkoutOrder.data[0].street}, {checkoutOrder.data[0].barangay}, {checkoutOrder.data[0].city}
        </Typography>
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Payment Method
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
        }}
      >
        <MopedIcon />
        <Typography sx={{ ml: 1 }}>Cash On Delivery</Typography>
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Order Summary
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {checkoutOrder.ready &&
          (checkoutOrder.data.length > 0 ? (
            checkoutOrder.data.map((data, index) => (
              <Box
                key={index}
                sx={{
                  mt: 1,
                  borderTop: "1px solid #AEAEAE",
                  borderLeft: "1px solid #AEAEAE",
                  borderBottom: "1px solid #AEAEAE",
                  borderRight: "4px solid green",
                  borderRadius: 2,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: 1
                }}>

                <Box sx={{ ml: 1 }}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    <b>{data.product}</b>
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                  >
                    {data.description}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                  >
                    <b>{`₱${data.price.toString(2)}`}</b>
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: 200 }}>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      {`x ${parseInt(data.quantity)}`}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1, ml: 4 }}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      &#8369;{`${(parseFloat(data.quantity) * parseFloat(data.price)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box sx={{ width: "100%", mt: 2 }}>
              <Typography
                align="center"
                sx={{
                  color: "red",
                  textAlign: "center",
                }}
              >
                No orders added
              </Typography>
            </Box>
          ))}
      </Box >

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>Subtotal</Typography>
          </Box>
          <Box>&#8369;{`${parseFloat(subtotalCheckout).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>Convenience Fee</Typography>
          </Box>
          <Box>
            &#8369;{`${parseFloat(checkoutOrder.data[0].delivery_fee).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>Total</Typography>
          </Box>
          <Box>
            <span style={{ fontWeight: 700, fontSize: 20 }}>
              &#8369;
              {`${(
                parseFloat(subtotalCheckout) +
                parseFloat(checkoutOrder.data[0].delivery_fee)
              ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            </span>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              borderRadius: 2,
              fontSize: 12,
              paddingX: 2,
              backgroundColor: "#d65d46",
              width: "150px",
              height: "40px",
            }}
            onClick={handleToCheckout}
            disabled={isProcess || checkoutOrder.data.length < 1}
          >
            Checkout
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CheckoutOrder;
