import React from "react";
import { Box, Divider, Typography } from "@mui/material";

const UserPaymentMethod = ({ userInfo }) => {
  return (
    <>
      <form>
        <Box
          sx={{
            mt: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography sx={{ fontWeight: 700 }}>My Payment Method</Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Divider light />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Typography color="blue" sx={{ fontWeight: 500 }}>
            Note: Cash on delivery is only available for now. We Still working
            in the online payment. It will be available soon.
          </Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Divider light />
        </Box>
      </form>
    </>
  );
};

export default UserPaymentMethod;
