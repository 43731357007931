import { Add, Close, FiberManualRecord } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';
import { UserInfo } from '../../context';

const Tables = ({ selectedData }) => {
    // console.log("selectedData", selectedData)
    const [addDineTablesDiag, setAddDineTablesDiag] = useState(false);
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = useContext(UserInfo);
    const [merchantTables, setMerchantTables] = useState({ data: [], ready: false });

    const handleNewMerchantTables = (e) => {
        e.preventDefault()
        e.persist()
        var formdata = new FormData(e.target)
        formdata.append("merchant_id", selectedData.merchant_id)
        axios
            .post("proprietor/create/new-tables", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setIsProcess(false);
                    setMessage("Successfully added tables.");
                    setSeverity("success");
                    getMerchantTables()
                    setTimeout(() => {
                        setAddDineTablesDiag(false)
                        setOpenSnackbar(true);
                    }, 500);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    }

    const getMerchantTables = useCallback(async () => {
        var params = { user_id: selectedData !== null && selectedData.merchant_id };
        await axios
            .get("merchant/get/tables", { params })
            .then((response) => {
                const data = response.data;
                setMerchantTables({ data, ready: true });
            })
            .catch((err) => console.log("err", err));
    }, [selectedData]);

    useEffect(() => {
        getMerchantTables()
    }, [getMerchantTables])

    return (
        <Box>
            <Box sx={{ display: "flex", mb: 1 }}>
                <Box flexGrow={1} />
                <Box>
                    <Tooltip title="Add Table">
                        <IconButton
                            onClick={() => {
                                setAddDineTablesDiag(true)
                            }}
                            size="small"
                        // disabled
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box>
                {merchantTables.data.length > 0 ? (
                    merchantTables.data.map((data, index) => (
                        <Box key={index} sx={{ border: "1px solid #e5e5e5", display: "flex", alignItems: "center", padding: 2, borderRadius: 4, mb: 1 }}>
                            <Box sx={{ flexGrow: 1, textTransform: "capitalize" }}>
                                {data.table_name}
                            </Box>
                            <Box sx={{ mr: 2 }}>
                                Capity: 1-{data.no_occupied} Persons
                            </Box>
                            <Box>
                                <FiberManualRecord sx={{ color: data.status === 1 ? 'green' : data.status === 0 ? 'red' : 'gray' }} />
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Box>
                        <Typography align='center' sx={{ color: "red" }}>
                            No table added.
                        </Typography>
                    </Box>
                )}
            </Box>

            <Dialog
                open={addDineTablesDiag}
                onClose={() => setAddDineTablesDiag(false)}
            >
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box flexGrow={1} sx={{ fontWeight: "bold", }}>Add New Table</Box>
                        <IconButton onClick={() => setAddDineTablesDiag(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box>
                        <form
                            onSubmit={handleNewMerchantTables}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                <TextField
                                    InputProps={{
                                        disableunderline: "true",
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Table Name"
                                    fullWidth
                                    name="table_name"
                                    required
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 2,
                                    mb: 2,
                                }}
                            ><TextField
                                    InputProps={{
                                        disableunderline: "true",
                                        inputProps: {
                                            min: 1,
                                            max: 99999999999,
                                            step: 0.01,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Capacity"
                                    fullWidth
                                    name="no_occupied"
                                    required
                                    type="number"
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Box flexGrow={1} />
                                <Box>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            textTransform: "capitalize",
                                            borderRadius: 2,
                                            fontSize: 12,
                                            paddingX: 2,
                                            backgroundColor: "#d65d46",
                                        }}
                                        type="submit"
                                        disabled={isProcess}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    )
}

export default Tables;