import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { MerchantInfo } from "../../context";
import { Chip, Dialog, IconButton, TablePagination } from "@mui/material";
import OrderDetails from "../order-details";
import { isMobile } from "react-device-detect";
import { Info } from "@mui/icons-material";

const MerchantOrders = () => {
  const { merchantProducts } = React.useContext(MerchantInfo);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [viewOrder, setViewOrder] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box
        sx={{
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "1165px",
              mx: "15px",
            }}
          >
            <Typography variant="h5">List of Orders</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "center",
            paddingX: "15px"
          }}
        >
          {merchantProducts.data.map((data, index) => (
            <Box
              sx={{
                borderTop: "1px solid #AEAEAE",
                borderLeft: "1px solid #AEAEAE",
                borderBottom: "1px solid #AEAEAE",
                borderRight: "4px solid green",
                borderRadius: 2,
                width: "100%",
                maxWidth: "1165px",
                display: "flex",
                alignItems: "center",
                padding: 1,
                mb: 1
              }}
              key={index}
            >
              <Box sx={{ mr: 2 }}>
                <AddBusinessIcon sx={{ fontSize: "28px" }} />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  sx={{ fontWeight: 700 }}
                >
                  {data.transactionID.replace("t-", "")}
                </Typography>
                <Typography
                  gutterBottom
                  variant="caption"
                >
                  {moment(data.created_at).fromNow()}
                </Typography>
              </Box>
              <Box flexGrow={1} />
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: 120 }}>
                <Chip size="small"
                  label={
                    data.status === "order-placed" ?
                      "Order placed"
                      : data.status === "order-preparing" ?
                        "Order preparing"
                        : data.status === "order-ready" ?
                          "Order ready"
                          : data.status === 'order-pickup' ?
                            "Order picked up"
                            : data.status === 'order-delivered' ?
                              data.rider_id === 'pick-up' ?
                                "Collected"
                                : "Delivered"
                              : "Waiting for rider"
                  }
                  color={data.status === 'order-delivered' ? 'success' : 'primary'}
                  variant="outlined" />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: isMobile ? 30 : 120 }}>
                {isMobile ? (
                  <IconButton
                    sx={{
                      fontWeight: 700,
                      fontSize: 12,
                      color: "#50C878",
                      alignItems: "center",
                      padding: 1
                    }}
                    onClick={() => {
                      setSelectedOrder(data);
                      setViewOrder(true);
                    }}
                  >
                    <Info />
                  </IconButton>
                ) : (
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 700,
                      fontSize: 12,
                      backgroundColor: "#50C878",
                      borderRadius: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: 1
                    }}
                    onClick={() => {
                      setSelectedOrder(data);
                      setViewOrder(true);
                    }}
                  >
                    ORDER DETAILS
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
          <Box sx={{
            display: "flex",
            width: "100%",
            maxWidth: "1165px",
          }}>
            <Box flexGrow={1} />
            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={merchantProducts.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>

        <Dialog
          open={viewOrder}
          onClose={() => setViewOrder(false)}
          maxWidth="xs"
          fullWidth
        >
          <OrderDetails
            selectedOrder={selectedOrder}
            close={() => {
              setViewOrder(false);
            }}
            route="order"
          />
        </Dialog>
      </Box>
    </>
  );
};
export default MerchantOrders;
