import { Add, Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Dialog, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';
import { UserInfo } from '../../context';

const Cashiers = ({ selectedData }) => {
    // console.log("selectedData", selectedData)
    const [addCashierDiag, setAddCashierDiag] = useState(false);
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = useContext(UserInfo);
    const [merchantCashier, setMerchantCashier] = useState({ data: [], ready: false })
    const [passwordShow, setPasswordShow] = useState(false);

    const getMerchantCashier = useCallback(async () => {
        var params = { merchant_id: selectedData !== null && selectedData.merchant_id };
        await axios
            .get("proprietor/get/cashier-by-id", { params })
            .then((response) => {
                const data = response.data;
                setMerchantCashier({ data, ready: true });
            })
            .catch((err) => console.log("err", err));
    }, [selectedData]);

    // 
    const handleCreateNewCashier = (e) => {
        e.persist();
        e.preventDefault();
        var formdata = new FormData(e.target);
        formdata.append("merchant_id", selectedData !== null && selectedData.merchant_id)
        setIsProcess(true);
        setAddCashierDiag(false);
        axios
            .post("proprietor/create/cashier", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setMessage("Successfully added cashier");
                    setSeverity("success");
                    setTimeout(() => {
                        setIsProcess(false);
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            getMerchantCashier();
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    };

    useEffect(() => {
        getMerchantCashier()
    }, [getMerchantCashier])

    return (
        <>
            {merchantCashier.ready && (
                <Box>
                    <Box sx={{ display: "flex", mb: 1 }}>
                        <Box flexGrow={1} />
                        <Box>
                            <Tooltip title="Edit Info">
                                <span>
                                    <IconButton
                                        onClick={() => {
                                            setAddCashierDiag(true)
                                        }}
                                        size="small"
                                    // disabled={merchantCashier.data && merchantCashier.data.email.length < 0}
                                    >
                                        <Add />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                    {merchantCashier.data.length > 0 ? (
                        merchantCashier.data.map((data, index) => (
                            <Box key={index} sx={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", }}>
                                <Typography>
                                    <span style={{ fontWeight: "bold" }}>Email:</span> {data.email}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "bold" }}>Name:</span>  {data.firstname} {data.lastname}
                                </Typography>
                                <Typography>
                                    <span style={{ fontWeight: "bold" }}>Contact:</span> {data.contact_no}
                                </Typography>
                            </Box>
                        ))
                    ) : (
                        <Box>
                            <Typography align='center' sx={{ color: "red" }}>
                                No cashier added.
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}

            <Dialog
                open={addCashierDiag}
                onClose={() => setAddCashierDiag(false)}
            >
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box flexGrow={1} sx={{ fontWeight: "bold", }} />
                        <IconButton onClick={() => setAddCashierDiag(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box>
                        <form
                            onSubmit={handleCreateNewCashier}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mb: 1,
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <TextField
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000",
                                                        fontWeight: 700,
                                                    },
                                                    "& .MuiInput-input": {
                                                        borderRadius: "8px",
                                                        paddingTop: "12px",
                                                        paddingBottom: "12px",
                                                        paddingLeft: "14px",
                                                        paddingRight: "14px",
                                                        fontSize: 14,
                                                    },
                                                    "& label + .MuiInput-root-emKjRF": {
                                                        marginTop: "22px",
                                                    },
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    inputProps: {
                                                        style: {
                                                            color: "#000",
                                                            backgroundColor: "#f4f5f7",
                                                            fontWeight: 700,
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="First Name"
                                                variant="standard"
                                                fullWidth
                                                placeholder="First Name Here"
                                                size="small"
                                                name="firstname"
                                                required
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <TextField
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000",
                                                        fontWeight: 700,
                                                    },
                                                    "& .MuiInput-input": {
                                                        borderRadius: "8px",
                                                        paddingTop: "12px",
                                                        paddingBottom: "12px",
                                                        paddingLeft: "14px",
                                                        paddingRight: "14px",
                                                        fontSize: 14,
                                                    },
                                                    "& label + .MuiInput-root-emKjRF": {
                                                        marginTop: "22px",
                                                    },
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    inputProps: {
                                                        style: {
                                                            color: "#000",
                                                            backgroundColor: "#f4f5f7",
                                                            fontWeight: 700,
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Last Name"
                                                variant="standard"
                                                fullWidth
                                                placeholder="Last Name Here"
                                                size="small"
                                                name="lastname"
                                                required
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <TextField
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000",
                                                        fontWeight: 700,
                                                    },
                                                    "& .MuiInput-input": {
                                                        borderRadius: "8px",
                                                        paddingTop: "12px",
                                                        paddingBottom: "12px",
                                                        paddingLeft: "14px",
                                                        paddingRight: "14px",
                                                        fontSize: 14,
                                                    },
                                                    "& label + .MuiInput-root-emKjRF": {
                                                        marginTop: "22px",
                                                    },
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    inputProps: {
                                                        style: {
                                                            color: "#000",
                                                            backgroundColor: "#f4f5f7",
                                                            fontWeight: 700,
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Contact Number"
                                                variant="standard"
                                                fullWidth
                                                placeholder="Contact Number Here"
                                                size="small"
                                                name="contact_no"
                                                required
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",

                                            }}
                                        >
                                            <TextField
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000",
                                                        fontWeight: 700,
                                                    },
                                                    "& .MuiInput-input": {
                                                        borderRadius: "8px",
                                                        paddingTop: "12px",
                                                        paddingBottom: "12px",
                                                        paddingLeft: "14px",
                                                        paddingRight: "14px",
                                                        fontSize: 14,
                                                    },
                                                    "& label + .MuiInput-root-emKjRF": {
                                                        marginTop: "22px",
                                                    },
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    inputProps: {
                                                        style: {
                                                            color: "#000",
                                                            backgroundColor: "#f4f5f7",
                                                            fontWeight: 700,
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Email Address"
                                                variant="standard"
                                                fullWidth
                                                placeholder="Email Address Here"
                                                size="small"
                                                name="email"
                                                required
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* here */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mb: 2,
                                }}
                            >
                                <TextField
                                    sx={{
                                        "& .MuiInputLabel-root": {
                                            color: "#000",
                                            fontWeight: 700,
                                        },
                                        "& .MuiInput-input": {
                                            borderTopLeftRadius: "8px",
                                            borderBottomLeftRadius: "8px",
                                            paddingTop: "12px",
                                            paddingBottom: "12px",
                                            paddingLeft: "14px",
                                            paddingRight: "14px",
                                            fontSize: 14,
                                        },
                                        "& label + .MuiInput-root-emKjRF": {
                                            marginTop: "22px",
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                        inputProps: {
                                            style: {
                                                color: "#000",
                                                backgroundColor: "#f4f5f7",
                                                fontWeight: 700,
                                            },
                                        },
                                        endAdornment: (
                                            <InputAdornment
                                                sx={{
                                                    minHeight: "44px",
                                                    marginLeft: '0px',
                                                    backgroundColor: "rgb(244, 245, 247)",
                                                    borderTopRightRadius: "8px",
                                                    borderBottomRightRadius: "8px"
                                                }}
                                                variant="filled"
                                                position="end"
                                            >
                                                {passwordShow ? (
                                                    <VisibilityOff
                                                        fontSize="small"
                                                        sx={{ color: "#AEAEAE", cursor: "pointer", marginRight: '10px' }}
                                                        onClick={() => setPasswordShow(false)}
                                                    />
                                                ) : (
                                                    <Visibility
                                                        fontSize="small"
                                                        sx={{ color: "#AEAEAE", cursor: "pointer", marginRight: '10px' }}
                                                        onClick={() => setPasswordShow(true)}
                                                    />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Set Password"
                                    variant="standard"
                                    fullWidth
                                    size="small"
                                    name="new_password"
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="•••••••"
                                    required
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    mb: 1,
                                }}
                            >
                                <Button
                                    sx={{
                                        color: "#fff",
                                        textTransform: "none",
                                        height: 40,
                                        backgroundColor: "#261138",
                                    }}
                                    fullWidth
                                    size="small"
                                    type="submit"
                                    disabled={isProcess}
                                >
                                    Create cashier account
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default Cashiers;