import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";
import MerchantConfirmPayments from "./MerchantConfirmPayments";
import MerchantList from "./MerchantList";
import MerchantPayments from "./MerchantPayments";

const MerchantsAbout = ({ userRestriction, module }) => {
  const [tabPanel, setTabPanel] = React.useState("list");
  const handleChangeMethod = (event, newValue) => {
    setTabPanel(newValue);
  };
  const allowAccount = userRestriction.find(
    (x) => parseInt(x.restriction_id) === 1
  );
  const allowPayment = userRestriction.find(
    (x) => parseInt(x.restriction_id) === 2
  );

  return (
    <>
      <TabContext value={tabPanel}>
        <Box
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Box>
            <TabList
              onChange={handleChangeMethod}
              aria-label="tabs"
              TabIndicatorProps={{ style: { background: "#1AE294" } }}
            >
              <Tab
                className="customtabbutton"
                label="Merchant List"
                value="list"
              />
              {module === "superadmin" ? (
                <Tab
                  className="customtabbutton"
                  label="Merchant Payments"
                  value="payments"
                />
              ) : null}

              {module === "superadmin" ? (
                <Tab
                  className="customtabbutton"
                  label="Confirmed Payments"
                  value="confirm"
                />
              ) : null}

              {module === "admin" && parseInt(allowPayment.allow) === 1 ? (
                <Tab
                  className="customtabbutton"
                  label="Merchant Payments"
                  value="payments"
                />
              ) : null}
              {module === "admin" && parseInt(allowPayment.allow) === 1 ? (
                <Tab
                  className="customtabbutton"
                  label="Confirmed Payments"
                  value="confirm"
                />
              ) : null}
            </TabList>
          </Box>

          <TabPanel value="list" className="tabpanelzeropadding">
            <MerchantList allowAccount={allowAccount} module={module} />
          </TabPanel>
          <TabPanel value="payments" className="tabpanelzeropadding">
            <MerchantPayments allowPayment={allowPayment} module={module} />
          </TabPanel>
          <TabPanel value="confirm" className="tabpanelzeropadding">
            <MerchantConfirmPayments />
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
};

export default MerchantsAbout;
