import React, { useContext } from "react";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import axios from "axios";
import { UserInfo } from "../../context";

const RiderCredentials = ({ userInfo, userData }) => {
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
        useContext(UserInfo);

    const handleUpdatePassword = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);
        formdata.set("user_id", userData.data.id);
        formdata.set("email", userData.data.email);
        setIsProcess(true);
        axios
            .post("update/password", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setIsProcess(false);
                    setMessage("Successfully update password.");
                    setSeverity("success");
                    setTimeout(() => {
                        setOpenSnackbar(true);
                    }, 500);
                } else {
                    setIsProcess(false);
                    setMessage("Invalid current password.");
                    setSeverity("error");
                    setTimeout(() => {
                        setOpenSnackbar(true);
                    }, 500);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    };

    return (
        <>
            <form onSubmit={handleUpdatePassword}>
                <Box
                    sx={{
                        mt: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                    }}
                >
                    <Typography sx={{ fontWeight: 700 }}>My Credentials</Typography>
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Divider light />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                    }}
                >
                    <TextField
                        sx={{
                            "& .MuiInputLabel-root": {
                                color: "#000",
                                // fontFamily: "Futura Md Bt",
                                fontWeight: 700,
                            },
                            "& .MuiInput-input": {
                                borderRadius: "8px",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                                paddingLeft: "14px",
                                paddingRight: "14px",
                                fontSize: 14,
                            },
                            "& label + .MuiInput-root-emKjRF": {
                                marginTop: "18px",
                            },
                        }}
                        InputProps={{
                            disableunderline: "true",
                            inputProps: {
                                style: {
                                    color: "#AEAEAE",
                                    backgroundColor: "#fff",
                                },
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label="Current pasword"
                        fullWidth
                        size="small"
                        name="password"
                        defaultValue={"password"}
                        type={"password"}
                        required
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                    }}
                >
                    <TextField
                        sx={{
                            "& .MuiInputLabel-root": {
                                color: "#000",
                                // fontFamily: "Futura Md Bt",
                                fontWeight: 700,
                            },
                            "& .MuiInput-input": {
                                borderRadius: "8px",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                                paddingLeft: "14px",
                                paddingRight: "14px",
                                fontSize: 14,
                            },
                            "& label + .MuiInput-root-emKjRF": {
                                marginTop: "18px",
                            },
                        }}
                        InputProps={{
                            disableunderline: "true",
                            inputProps: {
                                style: {
                                    color: "#AEAEAE",
                                    backgroundColor: "#fff",
                                },
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label="New password"
                        fullWidth
                        size="small"
                        name="new_password"
                        defaultValue={"password"}
                        type={"password"}
                        required
                    />
                </Box>

                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <Box flexGrow={1} />
                    <Box>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: 2,
                                fontSize: 12,
                                paddingX: 2,
                                backgroundColor: "#d65d46",
                            }}
                            type="submit"
                            disabled={isProcess}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default RiderCredentials;
