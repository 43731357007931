import { Box } from "@mui/material";
import React, { useContext } from "react";
import Container from "../../../layouts/Container";
import { UserInfo } from "../../context";
import RiderCredentials from "./RiderCredentials";
import RiderDeleteAccount from "./RiderDeleteAccount";
import RiderInfoForm from "./RiderInfoForm";

const RiderProfile = () => {

    const { userData } = useContext(UserInfo);

    return (
        <Container>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    //   border: "1px solid blue",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "1200px",
                        maxWidth: "1200px",
                        // border: "1px solid red",
                        paddingX: "8px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingY: "30px",
                            maxWidth: "500px",
                            //   border: "1px solid green",
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            {/* <UserLocation userInfo={userInfo} userData={userData} /> */}
                            <RiderInfoForm
                                userData={userData}
                            />
                            <RiderCredentials
                                // userInfo={userInfo}
                                userData={userData}
                            />
                            {/* <UserPaymentMethod userInfo={userInfo} /> */}
                            <RiderDeleteAccount userData={userData} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "64px",
                    backgroundColor: "#131418",
                    bottom: 0,
                }}
            >
                <Typography sx={{ color: "#fff", fontSize: 14 }}>
                    &copy; 2022 esther. All rights reserved.
                </Typography>
            </Box> */}
        </Container>
    );
};

export default RiderProfile;
