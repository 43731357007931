import {
  Box,
  Button,
  // Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { UserInfo } from "../../context";
import { isMobile } from "react-device-detect";
import { Info } from "@mui/icons-material";

const OrderDetails = ({ selectedOrder, close, route }) => {
  const [orders, setOrder] = useState({ data: [], ready: false });
  const [orderTotal, setOrderTotal] = useState(0);
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);

  const calculate = (data) => {
    var total = 0;
    for (let x = 0; x < data.length; x++) {
      total +=
        (parseFloat(data[x].price) + parseInt(data[x].variant_add_charge)) *
        parseInt(data[x].quantity);
    }
    return total;
  };

  const getOrderDetails = useCallback(() => {
    var params = {
      transaction_id: selectedOrder.transactionID,
      is_viewed: parseInt(selectedOrder.is_viewed) === 0 ? "new" : "old",
    };
    axios
      .get("user/merchant/get/spec-order", { params })
      .then((response) => {
        const data = response.data;
        setOrder({ data, ready: true });
        setOrderTotal(calculate(data));
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, [selectedOrder]);

  const handlePrepareOrder = () => {
    var formdata = new FormData();
    formdata.append("transaction_id", selectedOrder.transactionID);
    axios
      .post("user/merchant/update/to-prepare/spec-order", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setIsProcess(false);
          setMessage("Successfully update order.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
            getOrderDetails();
          }, 500);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleCompleteOrder = () => {
    var formdata = new FormData();
    formdata.append("transaction_id", selectedOrder.transactionID);
    axios
      .post("user/merchant/update/to-complete/spec-order", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setIsProcess(false);
          setMessage("Successfully completed the order.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
            getOrderDetails();
          }, 500);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handlePickedupOrder = () => {
    var formdata = new FormData();
    formdata.append("transaction_id", selectedOrder.transactionID);
    axios
      .post("user/merchant/update/to-pickedup/spec-order", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setIsProcess(false);
          setMessage("Successfully set to picked up order.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
            getOrderDetails();
          }, 500);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleCollectedOrder = () => {
    var formdata = new FormData();
    formdata.append("transaction_id", selectedOrder.transactionID);
    axios
      .post("user/merchant/update/to-collected/spec-order", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setIsProcess(false);
          setMessage("Successfully set to collected order.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
            getOrderDetails();
          }, 500);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getOrderDetails();
  }, [getOrderDetails]);

  return (
    <>
      {orders.ready && (
        <Box sx={{ padding: 2 }}>
          <Box sx={{ display: "flex" }}>
            <Box flexGrow={1} />
            <Box>
              <IconButton onClick={() => close()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ display: "flex", mb: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700 }}>
                Customer Information
              </Typography>
              {orders.data.length > 0 && (
                <>
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {orders.data[0].cus_fname} {orders.data[0].cus_lname}
                  </Typography>
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {orders.data[0].cus_con_no}
                  </Typography>
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {orders.data[0].street}, {orders.data[0].barangay},{" "}
                    {orders.data[0].city}
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700 }}>
                Rider Information
              </Typography>
              <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                {orders.data[0].rid_fname} {orders.data[0].rid_lname}
              </Typography>
              <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                {orders.data[0].rid_con_no}
              </Typography>
              <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                {orders.data[0].rid_street !== null
                  ? `${orders.data[0].rid_street},`
                  : ""}{" "}
                {orders.data[0].rid_barangay !== null
                  ? `${orders.data[0].rid_barangay},`
                  : ""}{" "}
                {orders.data[0].rid_city !== null
                  ? `${orders.data[0].rid_city}`
                  : ""}
              </Typography>
            </Box>

            {/* <Grid container spacing={2}>
              <Grid item xs={orders.data.length > 0 && orders.data[0].rider_id !== 'pick-up' ? 6 : 12}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ fontWeight: 700 }}>Customer Information</Typography>
                  {
                    orders.data.length > 0 && (
                      <>
                        <Typography style={{ fontSize: 14, marginLeft: 10 }}>{orders.data[0].cus_fname} {orders.data[0].cus_lname}</Typography>
                        <Typography style={{ fontSize: 14, marginLeft: 10 }}>{orders.data[0].cus_con_no}</Typography>
                        <Typography style={{ fontSize: 14, marginLeft: 10 }}>{orders.data[0].street}, {orders.data[0].barangay}, {orders.data[0].city}</Typography>
                      </>
                    )
                  }
                </Box>
              </Grid>
              {orders.data.length > 0 && orders.data[0].rider_id !== 'pick-up' && (
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography style={{ fontWeight: 700 }}>Rider Information</Typography>
                    <Typography style={{ fontSize: 14, marginLeft: 10 }}>{orders.data[0].rid_fname} {orders.data[0].rid_lname}</Typography>
                    <Typography style={{ fontSize: 14, marginLeft: 10 }}>{orders.data[0].rid_con_no}</Typography>
                    <Typography style={{ fontSize: 14, marginLeft: 10 }}>{orders.data[0].rid_street !== null ? `${orders.data[0].rid_street},` : ""} {orders.data[0].rid_barangay !== null ? `${orders.data[0].rid_barangay},` : ""} {orders.data[0].rid_city !== null ? `${orders.data[0].rid_city}` : ""}</Typography>
                  </Box>
                </Grid>
              )}
            </Grid> */}
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Order Number:</Typography>
            </Box>
            <Typography>
              {selectedOrder.transactionID.replaceAll("t-", "")}
            </Typography>
          </Box>

          {/* <Box sx={{ display: "flex" }}>
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Order Details</Typography>
            </Box>
          </Box> */}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <span style={{ fontWeight: 700 }}>Qty</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ fontWeight: 700 }}>Order</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ fontWeight: 700 }}>Amount</span>
                </TableCell>
                <TableCell style={{ width: 20 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.data.length > 0 ? (
                orders.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell align="right">{data.quantity}</TableCell>
                    <TableCell align="left">
                      {data.product}
                      {data.description !== null ? (
                        <>
                          <br /> {data.description}
                        </>
                      ) : (
                        ""
                      )}
                      {data.variant_desc !== null ? (
                        <>
                          <br /> {data.variant_desc}
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="right">
                      &#8369;
                      {(
                        parseFloat(data.price) +
                        parseFloat(data.variant_add_charge)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {data.special_instruction !== null && (
                        <Box>
                          <Tooltip
                            title={data.special_instruction}
                            followCursor
                          >
                            <Info
                              fontSize="small"
                              style={{ color: "blue", cursor: "pointer" }}
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No order found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  Total Cost
                </TableCell>
                <TableCell align="right">
                  &#8369;
                  {parseFloat(orderTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableFooter>
          </Table>
          {route === "order" && (
            <Box sx={{ display: "flex", mt: 2 }}>
              <Box flexGrow={1} />
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 2,
                    fontSize: 12,
                    paddingX: 2,
                    backgroundColor: "#261138",
                    mr: 1,
                  }}
                  onClick={handlePrepareOrder}
                  disabled={
                    isProcess ||
                    (orders.data.length > 0 &&
                      orders.data[0].status === "order-placed")
                      ? false
                      : true
                  }
                >
                  Prepare
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 2,
                    fontSize: 12,
                    paddingX: 2,
                    backgroundColor: "#1976d2",
                    mr: 1,
                  }}
                  onClick={handleCompleteOrder}
                  disabled={
                    isProcess ||
                    (orders.data.length > 0 &&
                      orders.data[0].status === "order-preparing")
                      ? false
                      : true
                  }
                >
                  Set Ready
                </Button>
              </Box>

              {orders.data.length > 0 &&
              orders.data[0].rider_id !== "pick-up" ? (
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize:
                        isMobile && orders.data[0].rider_id !== "pick-up"
                          ? 11
                          : 12,
                      paddingX: 2,
                      backgroundColor: "#AEAEAE",
                      height:
                        isMobile && orders.data[0].rider_id !== "pick-up"
                          ? "100%"
                          : "auto",
                    }}
                    onClick={handlePickedupOrder}
                    disabled={
                      isProcess ||
                      // (orders.data.length > 0 && parseInt(orders.data[0].rider_complete) === 0 ? false : true)
                      (orders.data.length > 0 &&
                        orders.data[0].status === "order-ready")
                        ? false
                        : true
                    }
                  >
                    Set Picked Up
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      paddingX: 2,
                      backgroundColor: "#AEAEAE",
                    }}
                    onClick={handleCollectedOrder}
                    disabled={
                      isProcess ||
                      (orders.data.length > 0 &&
                      orders.data[0].status === "order-delivered"
                        ? true
                        : false)
                    }
                  >
                    Set Collected
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default OrderDetails;
