import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Close } from "@mui/icons-material";
import { UserInfo } from "../../context";

const AdminList = ({ userList, getAdminList }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    React.useContext(UserInfo);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addAdminDialog, setAddAdminDialog] = React.useState(false);
  const [openCollapse, setOpenCollapse] = useState(null);
  const [userRestriction, setUserRestriction] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNewAdmin = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);

    setIsProcess(true);
    setAddAdminDialog(false);
    axios
      .post("user/admin/create/new-admin", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully added admin");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              getAdminList();
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  const handleGetRestriction = async (user_id) => {
    if (openCollapse === null) {
      var params = { user_id };
      await axios
        .get("user/admin/get/user-restriction", {
          params,
        })
        .then((response) => {
          const data = response.data;
          setOpenCollapse(user_id);
          setUserRestriction(data);
        })
        .catch((error) => console.log("Error: ", error));
    } else {
      setOpenCollapse(null);
    }
  };

  const handleCheck = async (method, admin_id, restriction_id) => {
    var formdata = new FormData();
    formdata.append("method", method);
    formdata.append("admin_id", admin_id);
    formdata.append("restriction_id", restriction_id);
    setIsProcess(true);
    await axios
      .post("user/admin/create/or/update/user-restriction", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully updated admin restriction");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              handleGetRestriction(admin_id);
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => console.log("Error: ", error));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex" }}>
        <Box flexGrow={1} />
        <Box>
          <Tooltip title="Add Admin">
            <IconButton
              onClick={() => {
                setAddAdminDialog(true);
              }}
            >
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Email</b>
              </TableCell>
              <TableCell align="center">
                <b>QR</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {userList.ready && userList.data.length > 0 ? (
              userList.data.map((data, index) => (
                <Fragment key={index}>
                  <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          handleGetRestriction(data.id);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell>{data.userqrcode.qr}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={5}
                    >
                      <Collapse
                        in={openCollapse === data.id ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Box>
                                  <FormGroup aria-label="position" row>
                                    {userRestriction.length > 0 &&
                                      userRestriction.map((item, itemIndex) => {
                                        if (
                                          parseInt(item.restriction_id) === 1
                                        ) {
                                          return (
                                            <FormControlLabel
                                              key={itemIndex}
                                              label={
                                                "Allow To Confirm Accounts"
                                              }
                                              control={
                                                <Checkbox
                                                  checked={
                                                    parseInt(item.allow) === 1
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() => {
                                                    if (
                                                      parseInt(item.allow) === 1
                                                    ) {
                                                      handleCheck(
                                                        "remove",
                                                        data.id,
                                                        item.restriction_id
                                                      );
                                                    } else {
                                                      handleCheck(
                                                        "create",
                                                        data.id,
                                                        item.restriction_id
                                                      );
                                                    }
                                                  }}
                                                />
                                              }
                                              labelPlacement="end"
                                            />
                                          );
                                        }

                                        if (
                                          parseInt(item.restriction_id) === 2
                                        ) {
                                          return (
                                            <FormControlLabel
                                              key={itemIndex}
                                              label={
                                                "Allow To Confirm Payments"
                                              }
                                              control={
                                                <Checkbox
                                                  checked={
                                                    parseInt(item.allow) === 1
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() => {
                                                    if (
                                                      parseInt(item.allow) === 1
                                                    ) {
                                                      handleCheck(
                                                        "remove",
                                                        data.id,
                                                        item.restriction_id
                                                      );
                                                    } else {
                                                      handleCheck(
                                                        "create",
                                                        data.id,
                                                        item.restriction_id
                                                      );
                                                    }
                                                  }}
                                                />
                                              }
                                              labelPlacement="end"
                                            />
                                          );
                                        }

                                        if (
                                          parseInt(item.restriction_id) === 3
                                        ) {
                                          return (
                                            <FormControlLabel
                                              key={itemIndex}
                                              label={"Allow To Voucher"}
                                              control={
                                                <Checkbox
                                                  checked={
                                                    parseInt(item.allow) === 1
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() => {
                                                    if (
                                                      parseInt(item.allow) === 1
                                                    ) {
                                                      handleCheck(
                                                        "remove",
                                                        data.id,
                                                        item.restriction_id
                                                      );
                                                    } else {
                                                      handleCheck(
                                                        "create",
                                                        data.id,
                                                        item.restriction_id
                                                      );
                                                    }
                                                  }}
                                                />
                                              }
                                              labelPlacement="end"
                                            />
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                  </FormGroup>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} style={{ color: "red" }}>
                  No admin found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={userList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={addAdminDialog}
        onClose={() => setAddAdminDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Add Admin</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setAddAdminDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            <form onSubmit={handleNewAdmin}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                      // fontFamily: "Futura Md Bt",
                      fontWeight: 700,
                    },
                    "& .MuiInput-input": {
                      borderRadius: "8px",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      fontSize: 14,
                    },
                    "& label + .MuiInput-root-emKjRF": {
                      marginTop: "18px",
                    },
                  }}
                  InputProps={{
                    disableunderline: "true",
                    inputProps: {
                      style: {
                        color: "#AEAEAE",
                        backgroundColor: "#fff",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Full Name"
                  fullWidth
                  size="small"
                  name="name"
                  required
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                      // fontFamily: "Futura Md Bt",
                      fontWeight: 700,
                    },
                    "& .MuiInput-input": {
                      borderRadius: "8px",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      fontSize: 14,
                    },
                    "& label + .MuiInput-root-emKjRF": {
                      marginTop: "18px",
                    },
                  }}
                  InputProps={{
                    disableunderline: "true",
                    inputProps: {
                      style: {
                        color: "#AEAEAE",
                        backgroundColor: "#fff",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Email"
                  fullWidth
                  size="small"
                  name="email"
                  required
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                      // fontFamily: "Futura Md Bt",
                      fontWeight: 700,
                    },
                    "& .MuiInput-input": {
                      borderRadius: "8px",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      fontSize: 14,
                    },
                    "& label + .MuiInput-root-emKjRF": {
                      marginTop: "18px",
                    },
                  }}
                  InputProps={{
                    disableunderline: "true",
                    inputProps: {
                      style: {
                        color: "#AEAEAE",
                        backgroundColor: "#fff",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Set Password"
                  fullWidth
                  size="small"
                  name="password"
                  required
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      paddingX: 2,
                      backgroundColor: "#d65d46",
                    }}
                    type="submit"
                    disabled={isProcess}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AdminList;
