import { Box } from "@mui/material";
import React from "react";
import SignUpEmail from "../../layouts/login/SignUpEmail";
// import { isMobile } from "react-device-detect";

const GuestSignupPage = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          //   alignItems: isMobile ? "normal" : "center",
          width: "100%",
          height: "calc(100vh - 64px)",
        }}
      >
        <SignUpEmail />
      </Box>
    </>
  );
};

export default GuestSignupPage;
