// import { Edit } from "@mui/icons-material";
import {
  Box,
  // IconButton, Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

const Managers = ({ data }) => {
  return (
    <>
      {/* <Box sx={{ display: "flex" }}>
                <Box flexGrow={1} />
                <Box>
                    <Tooltip title="Edit Info">
                        <span>
                            <IconButton
                                // onClick={() => {
                                //     close()
                                // }}
                                size="small"
                                disabled
                            >
                                <Edit />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography>
          <span style={{ fontWeight: "bold" }}>Email:</span> {data.reg_email}
        </Typography>
        <Typography>
          <span style={{ fontWeight: "bold" }}>Name:</span> {data.reg_firstname}{" "}
          {data.reg_lastname}
        </Typography>
        <Typography>
          <span style={{ fontWeight: "bold" }}>Contact:</span>{" "}
          {data.reg_contact_no}
        </Typography>
      </Box>
    </>
  );
};

export default Managers;
