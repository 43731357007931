import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { UserInfo } from "../../../../context";
import Close from "@mui/icons-material/Close";

const UpdateProductStatus = ({ getProductList, close, merchant_id, data }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const [openSnack, setOpenSnack] = useState(false);

  const handleStatusProduct = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("id", data && data?.id !== null ? data?.id : "");
    formdata.append("merchant_id", merchant_id);
    setIsProcess(true);
    close();
    axios
      .post("user/merchant/update/product-status", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully update product status");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              getProductList();
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  return (
    <>
      <Box sx={{ m: 2 }}>
        <form onSubmit={handleStatusProduct} encType="multipart/form-data">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box flexGrow={1}>
              <Typography sx={{ fontWeight: 700 }}>
                Edit Product Status
              </Typography>
            </Box>
            <IconButton size="small" onClick={() => close()}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Name"
              fullWidth
              name="product"
              value={data && data?.product !== null ? data?.product : ""}
              required
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              select
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Status"
              fullWidth
              name="status"
              defaultValue={data && data?.status !== null ? data?.status : ""}
              required
            >
              <option value=""> - Select status - </option>
              <option value={1}>Available</option>
              <option value={0}>Unavailable</option>
            </TextField>
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="contained"
                size="large"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: 2,
                  //   fontSize: 12,
                  paddingX: 2,
                  backgroundColor: "#261138",
                }}
                type="submit"
                disabled={isProcess}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenSnack(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Image of the product is required
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateProductStatus;
