import { Box } from "@mui/material";
import React from "react";

const CustomerVouchers = () => {
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "calc(100vh - 64px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          VOUCHERS
        </Box>
      </Box>
    </>
  );
};

export default CustomerVouchers;
