import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  Paper,
  Tooltip,
  CircularProgress,
  TablePagination,
  Collapse,
  ListItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Container from "../../../layouts/Container";
import { MerchantInfo, UserInfo } from "../../context";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Edit, Search } from "@mui/icons-material";
import axios from "axios";
import ProductForm from "./form/ProductForm";
import CategoryForm from "./form/CategoryForm";
import EditCategory from "./form/edit/EditCategory";
import { useNavigate } from "react-router-dom";
import EditProduct from "./form/edit/EditProduct";
import ImageViewer from "react-simple-image-viewer";
import UpdateProductStatus from "./form/edit/UpdateProductStatus";
import { isMobile } from "react-device-detect";
import SettingsIcon from "@mui/icons-material/Settings";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ConversionForm from "./form/ConversionForm";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import VariantForm from "./form/VariantForm";

const MerchantProduct = () => {
  const { merchantLocation } = useContext(MerchantInfo);
  const { userData } = useContext(UserInfo);
  const [categoryList, setCategoryList] = useState({ data: [], ready: false });
  const [productList, setProductList] = useState({ data: [], ready: false });
  const [conversionList, setConversionList] = useState({
    data: [],
    ready: false,
  });
  const [variantList, setVariantList] = useState({ data: [], ready: false });
  const [addProductDiag, setAddProductDiag] = useState(false);
  const [addProductCatDiag, setAddProductCatDiag] = useState(false);
  const [search, setSearch] = useState("");
  const [editOpen, setEditOpen] = useState({ data: null, open: false });
  const [editOpenProduct, setEditOpenProduct] = useState({
    data: null,
    open: false,
  });
  const [updateProductStatus, setUpdateProductStatus] = useState({
    data: null,
    open: false,
  });
  const [locationDialog, setLocationDialog] = useState(false);
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [availability, setAvailability] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [addConversionDiag, setAddConversionDiag] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [addVariantDiag, setAddVariantDiag] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFetchConversionList = (selectedData) => {
    var params = { id: selectedData.id };
    setConversionList({ data: [], ready: false });
    axios
      .get("user/merchant/get/conversion-list", { params })
      .then((response) => {
        const data = response.data;
        setSelectedConfig(selectedData);
        setConversionList({ data, ready: true });
      })
      .catch((err) => console.log("error", err));
  };

  const handleFetchVariantList = (selectedData) => {
    var params = { id: selectedData.id };
    setVariantList({ data: [], ready: false });
    axios
      .get("user/merchant/get/variant-list", { params })
      .then((response) => {
        const data = response.data;
        setSelectedVariant(selectedData);
        setVariantList({ data, ready: true });
      })
      .catch((err) => console.log("error", err));
  };

  const getCategoryList = () => {
    var params = { merchant_id: userData.data.id };
    axios
      .get("user/merchant/get/category-list", { params })
      .then((response) => {
        const data = response.data;
        setCategoryList({ data, ready: true });
      })
      .catch((err) => console.log("error", err));
  };

  const getProductList = () => {
    var params = { merchant_id: userData.data.id, status: "merchant" };
    axios
      .get("user/merchant/get/product-list", { params })
      .then((response) => {
        const data = response.data;
        setProductList({ data, ready: true });
        // setProductsCount(data.length);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getCategoryList();
    getProductList();
    // eslint-disable-next-line
  }, []);

  const searchable = productList.data.filter((data) => {
    return data.product.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Container>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            maxWidth: "1200px",
            paddingX: "8px",
          }}
        >
          <Grid container spacing={2} direction="row-reverse">
            <Grid item xs={12} sm={8}>
              <Box
                component={Paper}
                elevation={1}
                sx={{
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box flexGrow={1}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Product List
                    </Typography>
                  </Box>
                  <Box>
                    {isMobile ? (
                      <Tooltip title="Add Product">
                        <IconButton
                          onClick={() => {
                            if (
                              merchantLocation.data.latitude === undefined &&
                              merchantLocation.data.longitude === undefined
                            ) {
                              setLocationDialog(true);
                            } else {
                              setAddProductDiag(true);
                            }
                          }}
                        >
                          <PlaylistAddIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add Product">
                        <Button
                          startIcon={<PlaylistAddIcon />}
                          variant="text"
                          onClick={() => {
                            if (
                              merchantLocation.data.latitude === undefined &&
                              merchantLocation.data.longitude === undefined
                            ) {
                              setLocationDialog(true);
                            } else {
                              setAddProductDiag(true);
                            }
                          }}
                          sx={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          Add
                        </Button>
                      </Tooltip>
                    )}

                    <Tooltip title="Availability">
                      <IconButton
                        onClick={() => {
                          setAvailability(!availability);
                          if (selectedConfig !== null) {
                            setSelectedConfig(null);
                          }
                        }}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <TextField
                      label="Search product"
                      variant="outlined"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      autoComplete="off"
                      fullWidth
                    />
                  </Box>
                  {/* <Box>
                    <IconButton
                      onClick={() => {
                        setProductsView("list");
                      }}
                    >
                      <FormatListBulletedIcon
                        sx={{
                          color: productsView === "list" ? "#261138" : "none",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        setProductsView("card");
                      }}
                    >
                      <AppsIcon
                        sx={{
                          color: productsView === "card" ? "#261138" : "none",
                        }}
                      />
                    </IconButton>
                  </Box> */}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // border: "1px solid red",
                  }}
                >
                  <Grid container spacing={1}>
                    {productList.ready ? (
                      searchable.length > 0 ? (
                        searchable.map((data, index) => (
                          <Grid item xs={12} key={index}>
                            <Box
                              sx={{
                                mt: 1,
                                borderTop: "1px solid #e5e5e5",
                                borderLeft: "1px solid #e5e5e5",
                                borderBottom: "1px solid #e5e5e5",
                                borderRight:
                                  parseInt(data.status) === 1
                                    ? "4px solid green"
                                    : "4px solid #e5e5e5",
                                borderRadius: 2,
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                padding: 1,
                              }}
                            >
                              <CardMedia
                                component="img"
                                image={`${process.env.REACT_APP_API_IMAGE}merchant/products/${data.product_image}`}
                                height="70"
                                alt=""
                                sx={{ cursor: "pointer", width: 70 }}
                                onClick={() => {
                                  let dataArr = data.product_image.split(",");
                                  const res = dataArr.filter(
                                    (item) =>
                                      item.includes(".jpg") ||
                                      item.includes(".png") ||
                                      item.includes(".jpeg") ||
                                      item.includes(".JPG") ||
                                      item.includes(".PNG") ||
                                      item.includes(".JPEG")
                                  );
                                  setSelectedImages(
                                    res.map(
                                      (y) =>
                                        `${process.env.REACT_APP_API_IMAGE}merchant/products/` +
                                        y
                                    )
                                  );
                                  setIsViewerOpen(true);
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  ml: 1,
                                }}
                              >
                                <Typography
                                  // gutterBottom
                                  variant="subtitle1"
                                  // component="div"
                                >
                                  <b>{data.product}</b>
                                </Typography>
                                <Typography
                                  // gutterBottom
                                  variant="caption"
                                  // component="div"
                                >
                                  {data.description}
                                </Typography>
                                <Typography
                                  // gutterBottom
                                  variant="caption"
                                  // component="div"
                                >
                                  <b>{`₱${data.price.toString(2)}`}</b>
                                </Typography>
                                <Typography
                                  // gutterBottom
                                  variant="caption"
                                  // component="div"
                                >
                                  <b>
                                    Qty:{" "}
                                    {parseInt(data.total_initial_quantity) -
                                      parseInt(data.total_order_quantity)}
                                  </b>
                                </Typography>
                              </Box>
                              <Box flexGrow={1} />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {!availability && (
                                  <>
                                    <Tooltip title="Edit">
                                      <Edit
                                        onClick={() => {
                                          setEditOpenProduct({
                                            data,
                                            open: true,
                                          });
                                          if (selectedConfig !== null) {
                                            setSelectedConfig(null);
                                          }
                                        }}
                                        sx={{
                                          mr: 1,
                                          cursor: "pointer",
                                          color: "#1976d2",
                                        }}
                                        fontSize="small"
                                      />
                                    </Tooltip>

                                    <Tooltip title="Variant">
                                      <AltRouteIcon
                                        onClick={() => {
                                          if (selectedVariant !== null) {
                                            setSelectedVariant(null);
                                          } else {
                                            handleFetchVariantList(data);
                                          }
                                        }}
                                        sx={{
                                          mr: 1,
                                          cursor: "pointer",
                                          color: "#1976d2",
                                        }}
                                        // fontSize="small"
                                      />
                                    </Tooltip>

                                    <Tooltip title="Conversion Config">
                                      <SwapHorizIcon
                                        onClick={() => {
                                          if (selectedConfig !== null) {
                                            setSelectedConfig(null);
                                          } else {
                                            handleFetchConversionList(data);
                                          }
                                        }}
                                        sx={{
                                          cursor: "pointer",
                                          color: "#1976d2",
                                        }}
                                        // fontSize="small"
                                      />
                                    </Tooltip>
                                  </>
                                )}

                                {availability && (
                                  <Tooltip title="Availability">
                                    {parseInt(data.status) === 1 ? (
                                      <ToggleOffIcon
                                        onClick={() =>
                                          setUpdateProductStatus({
                                            data,
                                            open: true,
                                          })
                                        }
                                        sx={{
                                          // ml: 2,
                                          cursor: "pointer",
                                          color: "green",
                                        }}
                                        // fontSize="small"
                                      />
                                    ) : (
                                      <ToggleOnIcon
                                        onClick={() =>
                                          setUpdateProductStatus({
                                            data,
                                            open: true,
                                          })
                                        }
                                        sx={{
                                          ml: 2,
                                          cursor: "pointer",
                                          color: "#AEAEAE",
                                        }}
                                        // fontSize="small"
                                      />
                                    )}
                                  </Tooltip>
                                )}
                              </Box>
                            </Box>

                            <Collapse
                              in={
                                selectedConfig && selectedConfig.id === data.id
                              }
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box
                                sx={{
                                  mt: 1,
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ fontWeight: "bold" }} flexGrow={1}>
                                  Conversions
                                </Box>
                                <Box>
                                  <Tooltip title="Add Conversion">
                                    <Button
                                      startIcon={<SwapHorizIcon />}
                                      variant="text"
                                      onClick={() => {
                                        setAddConversionDiag(true);
                                      }}
                                      sx={{
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                      }}
                                      size="small"
                                    >
                                      Add
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  border: "1px solid #e5e5e5",
                                  borderRadius: 1,
                                }}
                              >
                                {conversionList.ready ? (
                                  conversionList.data.length > 0 ? (
                                    <List
                                      sx={{
                                        width: "100%",
                                        bgcolor: "background.paper",
                                      }}
                                    >
                                      {conversionList.data.map(
                                        (data2, key2) => (
                                          <ListItem
                                            key={key2}
                                            sx={{
                                              padding: 0,
                                              borderBottom:
                                                key2 + 1 !==
                                                conversionList.data.length
                                                  ? "1px solid #e5e5e5"
                                                  : "",
                                              paddingLeft: 1,
                                            }}
                                          >
                                            <ListItemText
                                              primary={`◉ ${data2.to_unit_qty} ${data.unit}(s) in 1 ${data2.to_unit}`}
                                            />
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  ) : (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "red",
                                      }}
                                    >
                                      No conversion found!
                                    </Box>
                                  )
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      color: "blue",
                                    }}
                                  >
                                    Loading...
                                  </Box>
                                )}
                              </Box>
                            </Collapse>

                            <Collapse
                              in={
                                selectedVariant &&
                                selectedVariant.id === data.id
                              }
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box
                                sx={{
                                  mt: 1,
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ fontWeight: "bold" }} flexGrow={1}>
                                  Variants
                                </Box>
                                <Box>
                                  <Tooltip title="Add Variant">
                                    <Button
                                      startIcon={<AltRouteIcon />}
                                      variant="text"
                                      onClick={() => {
                                        setAddVariantDiag(true);
                                      }}
                                      sx={{
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                      }}
                                      size="small"
                                    >
                                      Add
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  border: "1px solid #e5e5e5",
                                  borderRadius: 1,
                                }}
                              >
                                {variantList.ready ? (
                                  variantList.data.length > 0 ? (
                                    <List
                                      sx={{
                                        width: "100%",
                                        bgcolor: "background.paper",
                                      }}
                                    >
                                      {variantList.data.map((prod, item) => (
                                        <ListItem
                                          key={item}
                                          sx={{
                                            padding: 0,
                                            borderBottom:
                                              item + 1 !==
                                              variantList.data.length
                                                ? "1px solid #e5e5e5"
                                                : "",
                                            paddingLeft: 1,
                                          }}
                                        >
                                          <ListItemText
                                            primary={`◉ ${prod.description}`}
                                          />
                                          <Typography
                                            sx={{
                                              paddingRight: 1,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {prod.additional_cost !== "0.00"
                                              ? `₱${prod.additional_cost}`
                                              : "Free"}
                                          </Typography>
                                        </ListItem>
                                      ))}
                                    </List>
                                  ) : (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "red",
                                      }}
                                    >
                                      No variant found!
                                    </Box>
                                  )
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      color: "blue",
                                    }}
                                  >
                                    Loading...
                                  </Box>
                                )}
                              </Box>
                            </Collapse>
                          </Grid>
                        ))
                      ) : (
                        <Box sx={{ width: "100%", mt: 2 }}>
                          <Typography
                            align="center"
                            sx={{
                              color: "red",
                              textAlign: "center",
                            }}
                          >
                            No products added
                          </Typography>
                        </Box>
                      )
                    ) : (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            height: 100,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box flexGrow={1} />
                        <TablePagination
                          rowsPerPageOptions={[10, 15, 25]}
                          component="div"
                          count={searchable.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                component={Paper}
                sx={{
                  padding: 1,
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box flexGrow={1}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Product Category
                    </Typography>
                  </Box>

                  {/* <Box>
                    <IconButton
                      onClick={() => {
                        setShowCategory(!showCategory);
                      }}
                    >
                      {showCategory ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                  </Box> */}

                  <Box>
                    {isMobile ? (
                      <Tooltip title="Add Category">
                        <IconButton
                          onClick={() => {
                            if (
                              merchantLocation.data.latitude === undefined &&
                              merchantLocation.data.longitude === undefined
                            ) {
                              setLocationDialog(true);
                            } else {
                              setAddProductCatDiag(true);
                            }
                          }}
                        >
                          <PlaylistAddIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add Category">
                        <Button
                          startIcon={<PlaylistAddIcon />}
                          variant="text"
                          onClick={() => {
                            if (
                              merchantLocation.data.latitude === undefined &&
                              merchantLocation.data.longitude === undefined
                            ) {
                              setLocationDialog(true);
                            } else {
                              setAddProductCatDiag(true);
                            }
                          }}
                          sx={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          Add
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    // display: showCategory ? "flex" : "none",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {categoryList.ready ? (
                    categoryList.data.length > 0 ? (
                      categoryList.data.map((data, index) => (
                        <List
                          key={index}
                          dense={true}
                          sx={{
                            width: "100%",
                            maxWidth: 560,
                            bgcolor: "background.paper",
                          }}
                          component="nav"
                        >
                          <ListItemButton>
                            <ListItemText primary={data.category} />
                            <Edit
                              sx={{
                                cursor: "pointer",
                                color: "#1976d2",
                              }}
                              fontSize="small"
                              onClick={() => setEditOpen({ data, open: true })}
                            />
                          </ListItemButton>
                        </List>
                      ))
                    ) : (
                      <Box sx={{ width: "100%", mt: 2 }}>
                        <Typography
                          align="center"
                          sx={{
                            color: "red",
                            textAlign: "center",
                          }}
                        >
                          No category added
                        </Typography>
                      </Box>
                    )
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        height: 100,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "64px",
          backgroundColor: "#131418",
          bottom: 0,
          position: "absolute",
        }}
      >
        <Typography sx={{ color: "#fff", fontSize: 14 }}>
          &copy; 2022 esther. All rights reserved.
        </Typography>
      </Box> */}

      {isViewerOpen && (
        <ImageViewer
          src={selectedImages}
          currentIndex={0}
          onClose={() => setIsViewerOpen(false)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}

      {/* set location */}
      <Dialog
        open={locationDialog}
        onClose={() => {
          setLocationDialog(false);
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 700 }}>
              Set location first to create new product
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: 2,
                fontSize: 12,
                paddingX: 2,
                backgroundColor: "#261138",
              }}
              fullWidth
              onClick={() => navigate("/profile")}
            >
              Go To Profile
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* new product */}
      <Dialog
        open={addProductDiag}
        onClose={() => setAddProductDiag(false)}
        fullWidth
        maxWidth="xs"
      >
        <ProductForm
          getProductList={() => getProductList()}
          close={() => setAddProductDiag(false)}
          merchant_id={userData.data.id}
          categoryList={categoryList}
        />
      </Dialog>

      {/* new category */}
      <Dialog
        open={addProductCatDiag}
        onClose={() => setAddProductCatDiag(false)}
        maxWidth="xs"
        fullWidth
      >
        <CategoryForm
          getCategoryList={() => getCategoryList()}
          close={() => setAddProductCatDiag(false)}
          merchant_id={userData.data.id}
        />
      </Dialog>

      {/* edit category  */}
      <Dialog
        open={editOpen.open}
        onClose={() => setEditOpen({ ...editOpen, data: null, open: false })}
        maxWidth="xs"
        fullWidth
      >
        <EditCategory
          getCategoryList={() => getCategoryList()}
          close={() => setEditOpen({ ...editOpen, data: null, open: false })}
          merchant_id={userData.data.id}
          data={editOpen.data}
        />
      </Dialog>

      {/* edit product  */}
      <Dialog
        open={editOpenProduct.open}
        onClose={() =>
          setEditOpenProduct({ ...editOpenProduct, data: null, open: false })
        }
        fullWidth
        maxWidth="xs"
      >
        <EditProduct
          getProductList={() => getProductList()}
          close={() =>
            setEditOpenProduct({ ...editOpenProduct, data: null, open: false })
          }
          merchant_id={userData.data.id}
          categoryList={categoryList}
          data={editOpenProduct.data}
        />
      </Dialog>

      {/* update product status  */}
      <Dialog
        open={updateProductStatus.open}
        onClose={() =>
          setUpdateProductStatus({
            ...updateProductStatus,
            data: null,
            open: false,
          })
        }
        fullWidth
        maxWidth="xs"
      >
        <UpdateProductStatus
          getProductList={() => getProductList()}
          close={() =>
            setUpdateProductStatus({
              ...updateProductStatus,
              data: null,
              open: false,
            })
          }
          merchant_id={userData.data.id}
          data={updateProductStatus.data}
        />
      </Dialog>

      {/* new conversion */}
      <Dialog
        open={addConversionDiag}
        onClose={() => setAddConversionDiag(false)}
        fullWidth
        maxWidth="xs"
      >
        <ConversionForm
          handleFetchConversionList={() =>
            handleFetchConversionList(selectedConfig)
          }
          close={() => setAddConversionDiag(false)}
          // merchant_id={userData.data.id}
          selectedConfig={selectedConfig}
        />
      </Dialog>

      {/* new variant */}
      <Dialog
        open={addVariantDiag}
        onClose={() => setAddVariantDiag(false)}
        fullWidth
        maxWidth="xs"
      >
        <VariantForm
          handleFetchVariantList={() => handleFetchVariantList(selectedVariant)}
          close={() => setAddVariantDiag(false)}
          // merchant_id={userData.data.id}
          selectedVariant={selectedVariant}
        />
      </Dialog>
    </Container>
  );
};

export default MerchantProduct;
