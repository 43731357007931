import { Box } from "@mui/material";
import React from "react";
import LoginUsingEmail from "../../layouts/login/LoginUsingEmail";

const GuestPage = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 64px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            // border: "1px solid red",
          }}
        >
          <LoginUsingEmail
            // close={() => setOpenDialogLogin(false)}
            tab="landingpage"
          />
          {/* <Typography style={{ fontWeight: "bold" }} variant="h5">
            Content Coming Soon!
          </Typography> */}
        </Box>
      </Box>
    </>
  );
};

export default GuestPage;
