import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import { DropzoneArea } from "react-mui-dropzone";
import { useState } from "react";
import { useContext } from "react";
import { UserInfo } from "../../context";
import { useNavigate } from "react-router-dom";

const RiderConfirmDocu = () => {
    const url = window.location.search;
    const urlClient = new URLSearchParams(url).get("id");
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = useContext(UserInfo);
    const [orcrImg, setOrcrImg] = useState([])
    const [driverLisImg, setDriverLisImg] = useState([])
    const [unitImg, setUnitImg] = useState([])

    const navigate = useNavigate()

    const handleImageChangeNew = (files, updater) => {
        updater(files);
    };

    const handleNewAttachments = (e) => {
        e.persist();
        e.preventDefault();

        var formdata = new FormData(e.target);
        formdata.append("rider_id", urlClient);
        formdata.append("or_cr", orcrImg[0]);
        formdata.append("driver_license", driverLisImg[0]);
        formdata.append("unit", unitImg[0]);

        var err = [];
        if (formdata.get("contact_no").length < 1) {
            err = "error";
            setMessage("Contact Number is required");
            setSeverity("error");
            setOpenSnackbar(true);
        }
        if (unitImg.length < 1) {
            err = "error";
            setMessage("Unit is required");
            setSeverity("error");
            setOpenSnackbar(true);
        }
        if (driverLisImg.length < 1) {
            err = "error";
            setMessage("Driver license is required");
            setSeverity("error");
            setOpenSnackbar(true);
        }
        if (orcrImg.length < 1) {
            err = "error";
            setMessage("OR/CR is required");
            setSeverity("error");
            setOpenSnackbar(true);
        }
        if (err.length > 0) {
            console.log("form has a problem!");
        } else {
            setIsProcess(true);
            axios
                .post("rider/update/new-attachments", formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === "success") {
                        setMessage("Successfully update your attachments, We'll review now your attachments. You will redirect to the dashboard.");
                        setSeverity("success");
                        setTimeout(() => {
                            setIsProcess(false);
                            setOpenSnackbar(true);
                            setTimeout(() => {
                                navigate('/')
                                navigate(0)
                            }, 2000);
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setIsProcess(false);
                    console.log("error", error);
                });
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: "100vw",
                        maxWidth: "1165px",
                    }}>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 2,
                            mt: 2,

                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",

                                mx: "15px",
                            }}
                        >
                            <Box>
                                <Typography variant="h5">Attachments for approval</Typography>
                            </Box>
                            <Box flexGrow={1} />
                        </Box>
                    </Box>

                    <form onSubmit={handleNewAttachments} encType="multipart/form-data">
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 2,
                                mt: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    mx: "15px",
                                }}
                            >
                                <DropzoneArea
                                    onChange={(e) => handleImageChangeNew(e, setOrcrImg)}
                                    acceptedFiles={["image/jpeg", "image/png"]}
                                    dropzoneText={"Upload OR/CR of the unit."}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    dropzoneClass={'dropZoneCustom'}
                                    dropzoneParagraphClass={'dropZoneCustomText'}
                                />
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 2,
                                mt: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    mx: "15px",
                                }}
                            >
                                <DropzoneArea
                                    onChange={(e) => handleImageChangeNew(e, setDriverLisImg)}
                                    acceptedFiles={["image/jpeg", "image/png"]}
                                    dropzoneText={"Upload your Drivers License."}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    dropzoneClass={'dropZoneCustom'}
                                    dropzoneParagraphClass={'dropZoneCustomText'}
                                />
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 2,
                                mt: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    mx: "15px",
                                }}
                            >
                                <DropzoneArea
                                    onChange={(e) => handleImageChangeNew(e, setUnitImg)}
                                    acceptedFiles={["image/jpeg", "image/png"]}
                                    dropzoneText={"Upload a picture of your unit ex. motorcycle."}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    dropzoneClass={'dropZoneCustom'}
                                    dropzoneParagraphClass={'dropZoneCustomText'}
                                />
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 2,
                                mt: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    mx: "15px",
                                }}
                            >
                                <TextField
                                    sx={{
                                        "& .MuiInputLabel-root": {
                                            color: "#000",
                                            // fontFamily: "Futura Md Bt",
                                            fontWeight: 700,
                                        },
                                        "& .MuiInput-input": {
                                            borderRadius: "8px",
                                            paddingTop: "6px",
                                            paddingBottom: "6px",
                                            paddingLeft: "14px",
                                            paddingRight: "14px",
                                            fontSize: 14,
                                        },
                                        "& label + .MuiInput-root-emKjRF": {
                                            marginTop: "18px",
                                        },
                                    }}
                                    InputProps={{
                                        disableunderline: "true",
                                        inputProps: {
                                            style: {
                                                color: "#AEAEAE",
                                                backgroundColor: "#fff",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Contact Number"
                                    fullWidth
                                    size="small"
                                    name="contact_no"
                                    //   required
                                    multiline
                                />
                            </Box>
                        </Box>


                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            <Box flexGrow={1} />
                            <Box>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        textTransform: "capitalize",
                                        borderRadius: 2,
                                        fontSize: 12,
                                        mx: '15px',
                                        backgroundColor: "#d65d46",
                                    }}
                                    type="submit"
                                    disabled={isProcess}
                                >
                                    Save Attachments
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>
        </>
    );
};
export default RiderConfirmDocu;
