import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import axios from "axios";
import { CustomerInfo, UserInfo } from "../../context";

const UserInfoForm = ({ userInfo, userData }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const { reRunInfo } = useContext(CustomerInfo);

  const [inputedFname, setInputedFname] = useState("");
  const [inputedLname, setInputedLname] = useState("");
  const [inputedMobile, setInputedMobile] = useState("");
  // const [inputedCity, setInputedCity] = useState("");
  // const [inputedBarangay, setInputedBarangay] = useState("");

  const [customerInfo, setCustomerInfo] = useState({ data: null, ready: false });

  // const cityList = [
  //   { value: "Davao City", name: "Davao City" },
  //   { value: "Tagum City", name: "Tagum City" },
  //   { value: "Panabo City", name: "Panabo City" },
  // ];

  // const davaoBrgy = [
  //   { value: "Acacia", name: "Acacia" },
  //   { value: "Agdao", name: "Agdao" },
  //   { value: "Alambre", name: "Alambre" },
  //   { value: "Alejandra Navarro", name: "Alejandra Navarro" },
  //   { value: "Alfonso Angliongto Sr.", name: "Alfonso Angliongto Sr." },
  //   { value: "Angalan", name: "Angalan" },
  //   { value: "Atan-awe", name: "Atan-awe" },
  //   { value: "Baganihan", name: "Baganihan" },
  //   { value: "Bago Aplaya", name: "Bago Aplaya" },
  //   { value: "Bago Gallera", name: "Bago Gallera" },
  //   { value: "Bago Oshiro", name: "Bago Oshiro" },
  //   { value: "Baguio", name: "Baguio" },
  //   { value: "Balengaeng", name: "Balengaeng" },
  //   { value: "Baliok", name: "Baliok" },
  //   { value: "Bangkas Heights", name: "Bangkas Heights" },
  //   { value: "Bantol", name: "Bantol" },
  //   { value: "Baracatan", name: "Baracatan" },
  //   { value: "Barangay 10-A", name: "Barangay 10-A" },
  //   { value: "Barangay 11-B", name: "Barangay 11-B" },
  //   { value: "Barangay 12-B", name: "Barangay 12-B" },
  //   { value: "Barangay 13-B", name: "Barangay 13-B" },
  //   { value: "Barangay 14-B", name: "Barangay 14-B" },
  //   { value: "Barangay 15-B", name: "Barangay 15-B" },
  //   { value: "Barangay 16-B", name: "Barangay 16-B" },
  //   { value: "Barangay 17-B", name: "Barangay 17-B" },
  //   { value: "Barangay 18-B", name: "Barangay 18-B" },
  //   { value: "Barangay 19-B", name: "Barangay 19-B" },
  //   { value: "Barangay 1-A", name: "Barangay 1-A" },
  //   { value: "Barangay 20-B", name: "Barangay 20-B" },
  //   { value: "Barangay 21-C", name: "Barangay 21-C" },
  //   { value: "Barangay 22-C", name: "Barangay 22-C" },
  //   { value: "Barangay 23-C", name: "Barangay 23-C" },
  //   { value: "Barangay 24-C", name: "Barangay 24-C" },
  //   { value: "Barangay 25-C", name: "Barangay 25-C" },
  //   { value: "Barangay 26-C", name: "Barangay 26-C" },
  //   { value: "Barangay 27-C", name: "Barangay 27-C" },
  //   { value: "Barangay 28-C", name: "Barangay 28-C" },
  //   { value: "Barangay 29-C", name: "Barangay 29-C" },
  //   { value: "Barangay 2-A", name: "Barangay 2-A" },
  //   { value: "Barangay 30-C", name: "Barangay 30-C" },
  //   { value: "Barangay 31-D", name: "Barangay 31-D" },
  //   { value: "Barangay 32-D", name: "Barangay 32-D" },
  //   { value: "Barangay 33-D", name: "Barangay 33-D" },
  //   { value: "Barangay 34-D", name: "Barangay 34-D" },
  //   { value: "Barangay 35-D", name: "Barangay 35-D" },
  //   { value: "Barangay 36-D", name: "Barangay 36-D" },
  //   { value: "Barangay 37-D", name: "Barangay 37-D" },
  //   { value: "Barangay 38-D", name: "Barangay 38-D" },
  //   { value: "Barangay 39-D", name: "Barangay 39-D" },
  //   { value: "Barangay 3-A", name: "Barangay 3-A" },
  //   { value: "Barangay 40-D", name: "Barangay 40-D" },
  //   { value: "Barangay 4-A", name: "Barangay 4-A" },
  //   { value: "Barangay 5-A", name: "Barangay 5-A" },
  //   { value: "Barangay 6-A", name: "Barangay 6-A" },
  //   { value: "Barangay 7-A", name: "Barangay 7-A" },
  //   { value: "Barangay 8-A", name: "Barangay 8-A" },
  //   { value: "Barangay 9-A", name: "Barangay 9-A" },
  //   { value: "Bato", name: "Bato" },
  //   { value: "Bayabas", name: "Bayabas" },
  //   { value: "Biao Escuela", name: "Biao Escuela" },
  //   { value: "Biao Guianga", name: "Biao Guianga" },
  //   { value: "Biao Joaquin", name: "Biao Joaquin" },
  //   { value: "Binugao", name: "Binugao" },
  //   { value: "Bucana", name: "Bucana" },
  //   { value: "Buda", name: "Buda" },
  //   { value: "Buhangin", name: "Buhangin" },
  //   { value: "Bunawan", name: "Bunawan" },
  //   { value: "Cabantian", name: "Cabantian" },
  //   { value: "Cadalian", name: "Cadalian" },
  //   { value: "Calinan", name: "Calinan" },
  //   { value: "Callawa", name: "Callawa" },
  //   { value: "Camansi", name: "Camansi" },
  //   { value: "Carmen", name: "Carmen" },
  //   { value: "Catalunan Grande", name: "Catalunan Grande" },
  //   { value: "Catalunan Pequeño", name: "Catalunan Pequeño" },
  //   { value: "Catigan", name: "Catigan" },
  //   { value: "Cawayan", name: "Cawayan" },
  //   { value: "Centro", name: "Centro" },
  //   { value: "Colosas", name: "Colosas" },
  //   { value: "Communal", name: "Communal" },
  //   { value: "Crossing Bayabas", name: "Crossing Bayabas" },
  //   { value: "Dacudao", name: "Dacudao" },
  //   { value: "Dalag", name: "Dalag" },
  //   { value: "Dalagdag", name: "Dalagdag" },
  //   { value: "Daliao", name: "Daliao" },
  //   { value: "Daliaon Plantation", name: "Daliaon Plantation" },
  //   { value: "Datu Salumay", name: "Datu Salumay" },
  //   { value: "Dominga", name: "Dominga" },
  //   { value: "Dumoy", name: "Dumoy" },
  //   { value: "Eden", name: "Eden" },
  //   { value: "Fatima", name: "Fatima" },
  //   { value: "Gatungan", name: "Gatungan" },
  //   { value: "Gov. Paciano Bangoy", name: "Gov. Paciano Bangoy" },
  //   { value: "Gov. Vicente Duterte", name: "Gov. Vicente Duterte" },
  //   { value: "Gumalang", name: "Gumalang" },
  //   { value: "Gumitan", name: "Gumitan" },
  //   { value: "Ilang", name: "Ilang" },
  //   { value: "Inayangan", name: "Inayangan" },
  //   { value: "Indangan", name: "Indangan" },
  //   { value: "Kap. Tomas Monteverde, Sr.", name: "Kap. Tomas Monteverde, Sr." },
  //   { value: "Kilate", name: "Kilate" },
  //   { value: "Lacson", name: "Lacson" },
  //   { value: "Lamanan", name: "Lamanan" },
  //   { value: "Lampianao", name: "Lampianao" },
  //   { value: "Langub", name: "Langub" },
  //   { value: "Lapu-lapu", name: "Lapu-lapu" },
  //   { value: "Leon Garcia, Sr.", name: "Leon Garcia, Sr." },
  //   { value: "Lizada", name: "Lizada" },
  //   { value: "Los Amigos", name: "Los Amigos" },
  //   { value: "Lubogan", name: "Lubogan" },
  //   { value: "Lumiad", name: "Lumiad" },
  //   { value: "Ma-a", name: "Ma-a" },
  //   { value: "Mabuhay", name: "Mabuhay" },
  //   { value: "Magsaysay", name: "Magsaysay" },
  //   { value: "Magtuod", name: "Magtuod" },
  //   { value: "Mahayag", name: "Mahayag" },
  //   { value: "Malabog", name: "Malabog" },
  //   { value: "Malagos", name: "Malagos" },
  //   { value: "Malamba", name: "Malamba" },
  //   { value: "Manambulan", name: "Manambulan" },
  //   { value: "Mandug", name: "Mandug" },
  //   { value: "Manuel Guianga", name: "Manuel Guianga" },
  //   { value: "Mapula", name: "Mapula" },
  //   { value: "Marapangi", name: "Marapangi" },
  //   { value: "Marilog", name: "Marilog" },
  //   { value: "Matina Aplaya", name: "Matina Aplaya" },
  //   { value: "Matina Biao", name: "Matina Biao" },
  //   { value: "Matina Crossing", name: "Matina Crossing" },
  //   { value: "Matina Pangi", name: "Matina Pangi" },
  //   { value: "Megkawayan", name: "Megkawayan" },
  //   { value: "Mintal", name: "Mintal" },
  //   { value: "Mudiang", name: "Mudiang" },
  //   { value: "Mulig", name: "Mulig" },
  //   { value: "New Carmen", name: "New Carmen" },
  //   { value: "New Valencia", name: "New Valencia" },
  //   { value: "Pampanga", name: "Pampanga" },
  //   { value: "Panacan", name: "Panacan" },
  //   { value: "Panalum", name: "Panalum" },
  //   { value: "Pandaitan", name: "Pandaitan" },
  //   { value: "Pangyan", name: "Pangyan" },
  //   { value: "Paquibato", name: "Paquibato" },
  //   { value: "Paradise Embak", name: "Paradise Embak" },
  //   { value: "Rafael Castillo", name: "Rafael Castillo" },
  //   { value: "Riverside", name: "Riverside" },
  //   { value: "Salapawan", name: "Salapawan" },
  //   { value: "Salaysay", name: "Salaysay" },
  //   { value: "Saloy", name: "Saloy" },
  //   { value: "San Antonio", name: "San Antonio" },
  //   { value: "San Isidro", name: "San Isidro" },
  //   { value: "Santo Niño", name: "Santo Niño" },
  //   { value: "Sasa", name: "Sasa" },
  //   { value: "Sibulan", name: "Sibulan" },
  //   { value: "Sirawan", name: "Sirawan" },
  //   { value: "Sirib", name: "Sirib" },
  //   { value: "Suawan", name: "Suawan" },
  //   { value: "Subasta", name: "Subasta" },
  //   { value: "Sumimao", name: "Sumimao" },
  //   { value: "Tacunan", name: "Tacunan" },
  //   { value: "Tagakpan", name: "Tagakpan" },
  //   { value: "Tagluno", name: "Tagluno" },
  //   { value: "Tagurano", name: "Tagurano" },
  //   { value: "Talandang", name: "Talandang" },
  //   { value: "Talomo", name: "Talomo" },
  //   { value: "Talomo River", name: "Talomo River" },
  //   { value: "Tamayong", name: "Tamayong" },
  //   { value: "Tambobong", name: "Tambobong" },
  //   { value: "Tamugan", name: "Tamugan" },
  //   { value: "Tapak", name: "Tapak" },
  //   { value: "Tawan-tawan", name: "Tawan-tawan" },
  //   { value: "Tibuloy", name: "Tibuloy" },
  //   { value: "Tibungco", name: "Tibungco" },
  //   { value: "Tigatto", name: "Tigatto" },
  //   { value: "Toril", name: "Toril" },
  //   { value: "Tugbok", name: "Tugbok" },
  //   { value: "Tungakalan", name: "Tungakalan" },
  //   { value: "Ubalde", name: "Ubalde" },
  //   { value: "Ula", name: "Ula" },
  //   { value: "Vicente Hizon Sr.", name: "Vicente Hizon Sr." },
  //   { value: "Waan", name: "Waan" },
  //   { value: "Wangan", name: "Wangan" },
  //   { value: "Wilfredo Aquino", name: "Wilfredo Aquino" },
  //   { value: "Wines", name: "Wines" },
  // ];

  // const panaboBrgy = [
  //   { value: "A. O. Floirendo", name: "A. O. Floirendo" },
  //   { value: "Datu Abdul Dadia", name: "Datu Abdul Dadia" },
  //   { value: "Buenavista", name: "Buenavista" },
  //   { value: "Cacao", name: "Cacao" },
  //   { value: "Cagagohan", name: "Cagagohan" },
  //   { value: "Consolacion", name: "Consolacion" },
  //   { value: "Dapco", name: "Dapco" },
  //   { value: "Gredu (Poblacion)", name: "Gredu (Poblacion)" },
  //   { value: "J.P. Laurel", name: "J.P. Laurel" },
  //   { value: "Kasilak", name: "Kasilak" },
  //   { value: "Katipunan", name: "Katipunan" },
  //   { value: "Katualan", name: "Katualan" },
  //   { value: "Kauswagan", name: "Kauswagan" },
  //   { value: "Kiotoy", name: "Kiotoy" },
  //   { value: "Little Panay", name: "Little Panay" },
  //   { value: "Lower Panaga (Roxas)", name: "Lower Panaga (Roxas)" },
  //   { value: "Mabunao", name: "Mabunao" },
  //   { value: "Maduao", name: "Maduao" },
  //   { value: "Malativas", name: "Malativas" },
  //   { value: "Manay", name: "Manay" },
  //   { value: "Nanyo", name: "Nanyo" },
  //   { value: "New Malaga (Dalisay)", name: "New Malaga (Dalisay)" },
  //   { value: "New Malitbog", name: "New Malitbog" },
  //   { value: "New Pandan (Poblacion)", name: "New Pandan (Poblacion)" },
  //   { value: "New Visayas", name: "New Visayas" },
  //   { value: "Quezon", name: "Quezon" },
  //   { value: "Salvacion", name: "Salvacion" },
  //   { value: "San Francisco (Poblacion)", name: "San Francisco (Poblacion)" },
  //   { value: "San Nicolas", name: "San Nicolas" },
  //   { value: "San Pedro", name: "San Pedro" },
  //   { value: "San Roque", name: "San Roque" },
  //   { value: "San Vicente", name: "San Vicente" },
  //   { value: "Santa Cruz", name: "Santa Cruz" },
  //   { value: "Santo Niño (Poblacion)", name: "Santo Niño (Poblacion)" },
  //   { value: "Sindaton", name: "Sindaton" },
  //   { value: "Southern Davao", name: "Southern Davao" },
  //   { value: "Tagpore", name: "Tagpore" },
  //   { value: "Tibungol", name: "Tibungol" },
  //   { value: "Upper Licanan", name: "Upper Licanan" },
  //   { value: "Waterfall", name: "Waterfall" },
  // ];

  // const tagumBrgy = [
  //   { value: "Apokon", name: "Apokon" },
  //   { value: "Bincungan", name: "Bincungan" },
  //   { value: "Busaon", name: "Busaon" },
  //   { value: "Canocotan", name: "Canocotan" },
  //   { value: "Cuambogan", name: "Cuambogan" },
  //   { value: "La Filipina", name: "La Filipina" },
  //   { value: "Liboganon", name: "Liboganon" },
  //   { value: "Madaum", name: "Madaum" },
  //   { value: "Magdum", name: "Magdum" },
  //   { value: "Magugpo Poblacion", name: "Magugpo Poblacion" },
  //   { value: "Magugpo East", name: "Magugpo East" },
  //   { value: "Magugpo North", name: "Magugpo North" },
  //   { value: "Magugpo South", name: "Magugpo South" },
  //   { value: "Magugpo West", name: "Magugpo West" },
  //   { value: "Mankilam", name: "Mankilam" },
  //   { value: "New Balamban", name: "New Balamban" },
  //   { value: "Nueva Fuerza", name: "Nueva Fuerza" },
  //   { value: "Pagsabangan", name: "Pagsabangan" },
  //   { value: "Pandapan", name: "Pandapan" },
  //   { value: "San Agustin", name: "San Agustin" },
  //   { value: "San Isidro", name: "San Isidro" },
  //   { value: "San Miguel (Camp 4)", name: "San Miguel (Camp 4)" },
  //   { value: "Visayan Village", name: "Visayan Village" },
  // ];

  const handleUpdate = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", userData.data.id);
    setIsProcess(true);
    axios
      .post("customer/update/info", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          reRunInfo();
          setIsProcess(false);
          setMessage("Successfully update your info.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
          }, 500);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  const getCustomerInfo = () => {
    var params = { user_id: userData.data.id, module: 'customer' };
    axios
      .get("user/get/info", { params })
      .then((response) => {
        const data = response.data;
        if (data.length > 0) {
          setCustomerInfo({ data: data[0], ready: true });
          setInputedFname(data[0].firstname)
          setInputedLname(data[0].lastname)
          setInputedMobile(data[0].contact_no)
          // setInputedCity(data[0].city);
          // setInputedBarangay(data[0].barangay);
        }
        else {
          setCustomerInfo({ data: null, ready: true });
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getCustomerInfo()
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {customerInfo.ready && (
        <form onSubmit={handleUpdate}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ fontWeight: 700 }}> My Profile</Typography>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Divider light />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Email Address"
              fullWidth
              size="small"
              name="email"
              value={userData.data.email !== null ? userData.data.email : ""}
              required
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="First name"
              fullWidth
              size="small"
              name="firstname"
              defaultValue={
                customerInfo.data !== null ? inputedFname : ""
              }
              required
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Last name"
              fullWidth
              size="small"
              name="lastname"
              defaultValue={
                customerInfo.data !== null ? inputedLname : ""
              }
              required
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Mobile Number"
              fullWidth
              size="small"
              name="contact_no"
              defaultValue={
                customerInfo.data !== null ? inputedMobile : ""
              }
              required
            />
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              select
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              label="City"
              fullWidth
              size="small"
              name="city"
              value={inputedCity}
              onChange={(e) => setInputedCity(e.target.value)}
              required
            >
              <option value=""> - Select city - </option>
              {cityList.map((data, index) => (
                <option key={index} value={data.value}>
                  {data.name}
                </option>
              ))}
            </TextField>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              select
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Barangay"
              fullWidth
              size="small"
              name="barangay"
              value={inputedBarangay}
              onChange={(e) => setInputedBarangay(e.target.value)}
              required
            >
              <option value=""> - Select category - </option>
              {inputedCity === "Davao City"
                ? davaoBrgy.map((data, index) => (
                  <option value={data.value} key={index}>
                    {data.name}
                  </option>
                ))
                : inputedCity === "Tagum City"
                  ? tagumBrgy.map((data, index) => (
                    <option value={data.value} key={index}>
                      {data.name}
                    </option>
                  ))
                  : inputedCity === "Panabo City"
                    ? panaboBrgy.map((data, index) => (
                      <option value={data.value} key={index}>
                        {data.name}
                      </option>
                    ))
                    : null}
            </TextField>
          </Box> */}

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="contained"
                size="small"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: 2,
                  fontSize: 12,
                  paddingX: 2,
                  backgroundColor: "#d65d46",
                }}
                type="submit"
                disabled={isProcess}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </>
  );
};

export default UserInfoForm;
