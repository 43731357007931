import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { CustomerInfo } from "../../context";
import Container from "../../../layouts/Container";
import InventoryIcon from "@mui/icons-material/Inventory";
import CartOrder from "./CartOrder";
import CheckoutOrder from "./CheckoutOrder";

const CustomerCart = () => {
  const { cartOrder, checkoutOrder } = useContext(CustomerInfo);

  return (
    <Container>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            maxWidth: "1200px",
            paddingX: "8px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stepper
              activeStep={
                checkoutOrder.data.length > 0
                  ? 2
                  : cartOrder.data.length > 0
                  ? 1
                  : 0
              }
              alternativeLabel
            >
              <Step>
                <StepLabel>
                  <span style={{ fontWeight: 700 }}>Menu</span>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <span style={{ fontWeight: 700 }}>Cart</span>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <span style={{ fontWeight: 700 }}>Checkout</span>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>

          {checkoutOrder.data.length > 0 ? (
            <CheckoutOrder />
          ) : cartOrder.data.length > 0 ? (
            <CartOrder />
          ) : (
            <Box
              sx={{
                mt: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <InventoryIcon sx={{ color: "#aeaeae", fontSize: 60 }} />
              <Typography sx={{ color: "#aeaeae" }}>No order placed</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default CustomerCart;
