import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import { UserInfo } from "../../context";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import cities from "../../../assets/city.json";
import provinces from "../../../assets/province.json";

const CityList = ({ cityList, getCityList }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    React.useContext(UserInfo);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addCityDialog, setAddCityDialog] = React.useState(false);
  const [initialAmount, setInitialAmount] = useState(0);

  const [selectedProvince, setSelectedProvince] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const selectRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNewCity = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);

    setIsProcess(true);
    setAddCityDialog(false);
    axios
      .post("user/admin/create/new-city", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully added city");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              getCityList();
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (selectedProvince) {
      const filtered = cities.filter(
        (city) => city.province_code === selectedProvince
      );
      setFilteredCities(filtered);
    } else {
      setFilteredCities([]);
    }
    const inputElement = selectRef.current?.input?.input;
    if (inputElement) {
      inputElement.setAttribute("autocomplete", "off");
    }
  }, [selectedProvince]);

  const getCityByCode = (code) => {
    const filtered = cities.filter((city) => city.city_code === code);
    return filtered[0].city_name;
  };

  const getProvinceByCode = (code) => {
    const filtered = provinces.filter((prov) => prov.province_code === code);
    return filtered[0].province_name;
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex" }}>
        <Box flexGrow={1} />
        <Box>
          <Tooltip title="Add Admin">
            <IconButton
              onClick={() => {
                setAddCityDialog(true);
              }}
            >
              <AddLocationAltIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Province</b>
              </TableCell>
              <TableCell align="center">
                <b>City/Municipality</b>
              </TableCell>
              <TableCell align="center">
                <b>Per Item</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {cityList.ready && cityList.data.length > 0 ? (
              cityList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{getProvinceByCode(data.province)}</TableCell>
                  <TableCell>{getCityByCode(data.city)}</TableCell>
                  <TableCell align="right">&#8369;{data.per_item}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} style={{ color: "red" }}>
                  No city added
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={cityList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={addCityDialog}
        onClose={() => setAddCityDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Add City</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setAddCityDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            <form onSubmit={handleNewCity}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Province Name"
                  name="province"
                  required
                  // value={inputedCity}
                  onChange={(e) => setSelectedProvince(e.target.value)}
                  ref={selectRef}
                >
                  <option value=""> - Select province - </option>
                  {provinces.map((province) => (
                    <option
                      key={province.province_code}
                      value={province.province_code}
                    >
                      {province.province_name}
                    </option>
                  ))}
                </TextField>
              </Box>
              {selectedProvince !== "" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="City/Municipality Name"
                    name="city"
                    required
                    ref={selectRef}
                  >
                    <option value=""> - Select city - </option>
                    {filteredCities.map((city) => (
                      <option key={city.city_code} value={city.city_code}>
                        {city.city_name}
                      </option>
                    ))}
                  </TextField>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 99999999999,
                      step: 0.01,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Amount Per Item"
                  fullWidth
                  name="per_item"
                  required
                  type="number"
                  value={initialAmount}
                  onChange={(e) => setInitialAmount(e.target.value)}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      paddingX: 2,
                      backgroundColor: "#ff9f1d",
                      color: "#fff",
                    }}
                    type="submit"
                    disabled={isProcess}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default CityList;
