import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import Container from "../../../layouts/Container";
import { MerchantInfo, UserInfo } from "../../context";
import MerchantCredentials from "./MerchantCredentials";
import MerchantInfoForm from "./MerchantInfoForm";
import MerchantLocation from "./MerchantLocation";

const MerchantProfile = () => {
  const { merchantInfo } = useContext(MerchantInfo);
  const { userData } = useContext(UserInfo);

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "1200px",
            maxWidth: "1200px",
            paddingX: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingY: "30px",
              maxWidth: "500px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <MerchantLocation
                merchantInfo={merchantInfo}
                userData={userData}
              />
              <MerchantInfoForm
                merchantInfo={merchantInfo}
                userData={userData}
              />
              <MerchantCredentials merchantInfo={merchantInfo} />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "64px",
          backgroundColor: "#131418",
          // bottom: 0,
          // position: "absolute",
        }}
      >
        <Typography sx={{ color: "#fff", fontSize: 14 }}>
          &copy; 2022 esther. All rights reserved.
        </Typography>
      </Box> */}
    </Container>
  );
};

export default MerchantProfile;
