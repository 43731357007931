import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MerchantPage from "../components/merchant";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { ManageAccounts } from "@mui/icons-material";
import MerchantProfile from "../components/merchant/profile";
import { MerchantInfo, UserInfo } from "../components/context";
import axios from "axios";
import MerchantProduct from "../components/merchant/product";
import MerchantAppbar from "../layouts/MerchantAppbar";
import MerchantOrders from "../components/merchant/orders";
import MerchantIncome from "../components/merchant/income";
import MerchantCollection from "../components/merchant/collection";
import GoogleCallback from "../components/google/GoogleCallback";
import MerchantExpense from "../components/merchant/expense";
import MerchantConfirmDocu from "../components/merchant/confirmdocuments";
import Sound from "../assets/sounds/Sound";
import MerchantUpgrade from "../components/merchant/profile/MerchantUpgrade";
import MerchantDelivery from "../components/merchant/delivery";
import MerchantDeliveryRecord from "../components/merchant/delivery-record";

const MerchantRoute = ({ module }) => {
  const { userData } = useContext(UserInfo);
  const [merchantInfo, setMerchantInfo] = useState({ data: [], ready: false });
  const [merchantLocation, setMerchantLocation] = useState({
    data: [],
    ready: false,
  });
  const [merchantProducts, setMerchantProducts] = useState({
    data: [],
    ready: false,
  });
  const [productNotView, setProductNotView] = useState(0);
  var interval = null;
  const [lastIdNotView, setLastIdNotView] = useState(0);
  const redirect = localStorage.getItem("estherRedirect");

  const getMerchantInfo = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("user/merchant/get/info", { params })
      .then((response) => {
        const data = response.data;
        setMerchantInfo({ data, ready: true });
      })
      .catch((err) => console.log("err", err));
  };

  const getMerchantLocation = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("user/merchant/get/location", { params })
      .then((response) => {
        const data = response.data;
        setMerchantLocation({ data, ready: true });
      })
      .catch((err) => console.log("err", err));
  };

  const calculate = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total += parseInt(data[i].is_viewed) === 0 ? 1 : 0 * 1;
    }
    return total;
  };

  const getMerchantOrdersFromCustomer = () => {
    var params = { user_id: userData.data.id, merchant_status: "all" };
    axios
      .get("user/merchant/get/orders", { params })
      .then((response) => {
        const data = response.data;
        setMerchantProducts({ data, ready: true });
        setProductNotView(calculate(data));

        if (data.length > 0) {
          setTimeout(() => {
            setLastIdNotView(parseInt(data[0].lastId));
          }, 1000);
        }
      })
      .catch((err) => console.log("err", err));
  };

  // const initializeInterval = () => {
  //   interval = setInterval(() => {
  //     getMerchantOrdersFromCustomer();
  //   }, 5000);
  // };

  const routeArray = [
    {
      name: "Profile",
      path: "/profile",
      icon: <ManageAccounts fontSize="small" />,
    },
    {
      name: "Product",
      path: "/product",
      icon: <Inventory2Icon fontSize="small" />,
    },
  ];

  useEffect(() => {
    // initializeInterval();
    getMerchantInfo();
    getMerchantLocation();
    getMerchantOrdersFromCustomer();

    if (redirect !== null) {
      localStorage.removeItem("estherRedirect");
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <MerchantInfo.Provider
        value={{
          merchantInfo,
          setMerchantInfo,
          reRunInfo: () => getMerchantInfo(),

          merchantLocation,
          setMerchantLocation,
          reRunLocation: () => getMerchantLocation(),

          merchantProducts,
          setMerchantProducts,
          reRunMerchantProducts: () => getMerchantOrdersFromCustomer(),
        }}
      >
        <MerchantAppbar
          module={module}
          route={routeArray}
          merchantProducts={merchantProducts}
          productNotView={productNotView}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Navigate to={redirect !== null ? redirect : "/merchant"} />
            }
          />
          <Route path="/merchant" element={<MerchantPage />} />
          <Route path="/profile" element={<MerchantProfile />} />
          <Route path="/product" element={<MerchantProduct />} />
          <Route path="/merchant/orders" element={<MerchantOrders />} />
          <Route path="/merchant/expense" element={<MerchantExpense />} />
          <Route path="/merchant/income" element={<MerchantIncome />} />
          <Route
            path="/merchant/esther-collection"
            element={<MerchantCollection />}
          />
          <Route
            path="/merchant/confirm-documents"
            element={<MerchantConfirmDocu />}
          />

          <Route path="/delivery" element={<MerchantDelivery />} />
          <Route path="/delivery-record" element={<MerchantDeliveryRecord />} />

          <Route path="/auth/google" element={<GoogleCallback />} />
          <Route path="/merchant/upgrade" element={<MerchantUpgrade />} />
        </Routes>
      </MerchantInfo.Provider>

      {merchantProducts.ready && merchantProducts.data.length > 0 && (
        <Sound id={lastIdNotView} />
      )}
    </React.Fragment>
  );
};

export default MerchantRoute;
