import {
  Box,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Container from "../../../layouts/Container";
import { UserInfo } from "../../context";
import axios from "axios";
import moment from "moment";
import { ArrowForward, Close } from "@mui/icons-material";
import DeliveryDetails from "./DeliveryDetails";

const MerchantDeliveryRecord = () => {
  const { userData } = useContext(UserInfo);
  const [deliveryList, setDeliveryList] = useState({ data: [], ready: false });
  const [selectedDeliveryData, setSelectedDeliveryData] = useState({
    open: false,
    data: null,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDeliveryList = () => {
    var params = { merchant_id: userData.data.id };
    axios
      .get("user/merchant/get/delivery-list", { params })
      .then((response) => {
        const data = response.data;
        setDeliveryList({ data, ready: true });
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDeliveryList();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            maxWidth: "1200px",
            paddingX: "8px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                component={Paper}
                elevation={1}
                sx={{
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box flexGrow={1}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Delivery List
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                  }}
                >
                  <TableContainer>
                    <Table size="small" sx={{ border: "1px solid #e5e5e5" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            DR Date
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            DR Number
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            Delivered By
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            Received By
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {deliveryList.ready ? (
                          deliveryList.data.length > 0 ? (
                            deliveryList.data.map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {moment(data.dr_date).format("MMMM DD, YYYY")}
                                </TableCell>
                                <TableCell>{data.dr_number}</TableCell>
                                <TableCell>{data.delivery_by}</TableCell>
                                <TableCell>{data.receive_by}</TableCell>
                                <TableCell align="center">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Tooltip title="Delivery Details">
                                      <IconButton
                                        onClick={() => {
                                          setSelectedDeliveryData({
                                            ...selectedDeliveryData,
                                            open: true,
                                            data,
                                          });
                                        }}
                                        size="small"
                                      >
                                        <ArrowForward sx={{ color: "blue" }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                align="center"
                                sx={{ color: "red" }}
                              >
                                No delivery added
                              </TableCell>
                            </TableRow>
                          )
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={5}
                              align="center"
                              sx={{ color: "blue" }}
                            >
                              Loading...
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={deliveryList.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Dialog
        open={selectedDeliveryData.open}
        onClose={() =>
          setSelectedDeliveryData({
            ...selectedDeliveryData,
            open: false,
            data: null,
          })
        }
        fullWidth
        maxWidth="md"
      >
        <Box sx={{ p: 3 }}>
          {selectedDeliveryData.data &&
            selectedDeliveryData.data.id !== null && (
              <>
                <Box sx={{ display: "flex" }}>
                  <Box flexGrow={1}>
                    <Typography sx={{ fontWeight: "bold" }} variant="h5">
                      {selectedDeliveryData.data.dr_number} Details
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() =>
                      setSelectedDeliveryData({
                        ...selectedDeliveryData,
                        open: false,
                        data: null,
                      })
                    }
                  >
                    <Close sx={{ color: "red" }} />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <DeliveryDetails
                    dr_number={
                      selectedDeliveryData.data &&
                      selectedDeliveryData.data.dr_number
                    }
                    merchant_id={userData.data.id}
                  />
                </Box>
              </>
            )}
        </Box>
      </Dialog>
    </Container>
  );
};

export default MerchantDeliveryRecord;
