import React, { useContext } from "react";
import { Box, Button, Typography, CardMedia } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { UserInfo } from "../../components/context";

const img1 = require("../../assets/socialmediaicon/google.png");

const LoginToUse = ({ close, open }) => {
  const { loginUrl } = useContext(UserInfo);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          width: "100%",
          height: `calc(100vh - 64px)`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border: "1px solid red",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "360px",
              //   border: "1px solid red",
              paddingX: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontFamily: "Futura Md Bt", fontWeight: 700 }}
              >
                Esther
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 4,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Futura Md Bt",
                  fontWeight: 500,
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                Login in or create your account. Use your email or another
                service to continue.
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Button
                sx={{
                  color: "#000",
                  textTransform: "none",
                  fontWeight: 700,
                  height: 40,
                  backgroundColor: "#f4f5f7",
                  borderRadius: 2,
                }}
                fullWidth
                size="small"
                type="submit"
                href={loginUrl}
                startIcon={
                  <CardMedia
                    component="img"
                    src={`${img1}`}
                    style={{ width: "20px" }}
                    alt=""
                  />
                }
              >
                <Typography
                  variant="subtitle2"
                  fontFamily={"Futura Md Bt"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Log in with Google
                </Typography>
              </Button>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Button
                sx={{
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: 700,
                  height: 40,
                  backgroundColor: "#d65d46",
                  borderRadius: 2,
                }}
                fullWidth
                size="small"
                onClick={() => {
                  open();
                  close();
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontFamily={"Futura Md Bt"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Continue with Email
                </Typography>
              </Button>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
              <Button
                sx={{
                  color: "#000",
                  textTransform: "none",
                  fontWeight: 700,
                  height: 40,
                  //   backgroundColor: "#2264f6",
                  borderRadius: 2,
                }}
                fullWidth
                size="small"
                onClick={() => close()}
              >
                <ArrowBackIos sx={{ fontSize: "12px ", color: "#AEAEAE" }} />
                <Typography
                  variant="subtitle2"
                  fontFamily={"Futura Md Bt"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Back
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Futura Md Bt",
                  fontWeight: 500,
                  textAlign: "center",
                  fontSize: 11,
                }}
              >
                By registering you agree to our{" "}
                <span style={{ color: "blue" }}>Terms of Use</span> and{" "}
                <span style={{ color: "blue" }}>Privacy Policy</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginToUse;
