import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: `100%`,
    height: `calc(100vh - 64px)`,
    alignItems: "center",
    backgroundColor: "#fff",
    flexGrow: 1,
    overflow: "auto",
    overflowX: "hidden",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  })
);

export default function Container({ children }) {
  return (
    <>
      <Main open={true}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            height: `calc(100vh - 64px)`,
          }}
        >
          {children}
        </Box>
      </Main>
    </>
  );
}
