import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  Typography,
  // CircularProgress
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { UserInfo } from "../context";
// import ChartistGraph from "react-chartist";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { Store } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const MerchantPage = () => {
  const [year, setYear] = useState([new Date().getFullYear()]);
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const selectedYear = new Date().getFullYear();
  const [series, setSeries] = useState({ data: [], ready: false });
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [merchantPerItem, setMerchantPerItem] = useState(0);

  const { userData } = useContext(UserInfo);
  const navigate = useNavigate();

  const [seriesExpense, setSeriesExpense] = useState({
    data: [],
    ready: false,
  });

  // console.log("series", series);
  // console.log("setSelectedYear", setSelectedYear);
  const getIncomeReport = async () => {
    try {
      var params = {
        merchant_id: userData.data.id,
        year: selectedYear,
      };
      const income = await axios.get("user/merchant/get/income", {
        params,
      });
      const data = income.data;
      if (data.length > 0) {
        setTotalQuantity(parseFloat(data[0].total_quantity));
        setTotalPayment(parseFloat(data[0].total_merchant_pay));
        setTotalIncome(parseFloat(data[0].total_income));
        setMerchantPerItem(parseFloat(data[0].merchant_per_item));
        setSeries({
          data: [
            data[0].jan_income,
            data[0].feb_income,
            data[0].mar_income,
            data[0].apr_income,
            data[0].may_income,
            data[0].jun_income,
            data[0].jul_income,
            data[0].aug_income,
            data[0].sep_income,
            data[0].oct_income,
            data[0].nov_income,
            data[0].dec_income,
          ],
          ready: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExpenseReport = async () => {
    try {
      var params = {
        merchant_id: userData.data.id,
        year: selectedYear,
      };
      const expense = await axios.get("user/merchant/get/expense-graph", {
        params,
      });
      const data = expense.data;
      if (data.length > 0) {
        setTotalExpense(parseFloat(data[0].total_expense));
        setSeriesExpense({
          data: [
            data[0].jan_expense,
            data[0].feb_expense,
            data[0].mar_expense,
            data[0].apr_expense,
            data[0].may_expense,
            data[0].jun_expense,
            data[0].jul_expense,
            data[0].aug_expense,
            data[0].sep_expense,
            data[0].oct_expense,
            data[0].nov_expense,
            data[0].dec_expense,
          ],
          ready: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateYear = () => {
    for (let i = 2022; i <= year; i++) {
      setYear((year) => year.concat(i++));
    }
  };

  React.useEffect(() => {
    getIncomeReport();
    getExpenseReport();
    setTimeout(() => {
      generateYear();
    }, 2000);
    return () => setSeries({ data: [], ready: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        border: "1px solid red",
        height: 200,
      },
      // title: {
      //   // display: true,
      //   // text: 'Chart.js Line Chart',
      // },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Expense",
        data: seriesExpense.data,
        borderColor: "rgb(220,20,60)",
        backgroundColor: "rgb(240,128,128)",
      },
      {
        fill: true,
        label: "Income",
        data: series.data,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          width: "100vw",
          maxWidth: "1200px",
          height: "calc(100vh - 64px)",
          paddingX: "8px",
        }}
      >
        <Grid container spacing={2}>
          <Grid sx={{ mt: 2 }} item xs={12} sm={4}>
            <Card sx={{ borderLeft: "4px solid red" }}>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ color: "#04D4F0", cursor: "pointer" }}
                      onClick={() => navigate("/merchant/expense")}
                    >
                      Expense
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      &#8369;{" "}
                      {totalExpense.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Box>
                  <Store fontSize="large" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid sx={{ mt: 2 }} item xs={12} sm={4}>
            <Card sx={{ borderLeft: "4px solid blue" }}>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ color: "#04D4F0", cursor: "pointer" }}
                      onClick={() => navigate("/merchant/income")}
                    >
                      Income
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      &#8369;{" "}
                      {totalIncome.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Box>
                  <AccountBalanceWalletIcon fontSize="large" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid sx={{ mt: 2 }} item xs={12} sm={4}>
            <Card sx={{ borderLeft: "4px solid green" }}>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ color: "#04D4F0", cursor: "pointer" }}
                      onClick={() => navigate("/merchant/esther-collection")}
                    >
                      Esther Collection
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      {/* &#8369; {(parseFloat(totalQuantity) - parseFloat(totalPayment)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                      &#8369;{" "}
                      {(
                        parseFloat(totalQuantity) *
                          parseFloat(merchantPerItem) -
                        parseFloat(totalPayment)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Box>
                  <SsidChartIcon fontSize="large" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box>
          <Line options={options} data={data} height={"100px"} />
        </Box>
      </Box>

      <Dialog
        open={parseInt(userData.data.status) === 0 ? true : false}
        disableEscapeKeyDown
        maxWidth="xs"
      >
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6">
            <b>Your account is currently verifying...</b>
          </Typography>
          <Typography variant="caption">
            <b>Note:</b>{" "}
            <i>
              Please wait for our customer service to contact you and ask for
              more information in order to activate your account.
            </i>
          </Typography>
          <Box sx={{ display: "flex", mt: 2 }}>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              onClick={() => {
                localStorage.removeItem("estherToken");
                setTimeout(() => {
                  navigate("/");
                  navigate(0);
                }, 1000);
              }}
            >
              Exit Account
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MerchantPage;
