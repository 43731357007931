import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  // CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Container from "../../layouts/Container";
import { CustomerInfo, UserInfo } from "../context";
// import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// import AppsIcon from "@mui/icons-material/Apps";
import { Search } from "@mui/icons-material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
// import ImageViewer from "react-simple-image-viewer";

const CustomerPage = () => {
  // const [productList, setProductList] = useState({ data: [], ready: false });
  const [merchantList, setMerchantList] = useState({ data: [], ready: false });
  const {
    userData,
    setIsProcess,
    isProcess,
    setMessage,
    setSeverity,
    setOpenSnackbar,
  } = useContext(UserInfo);
  const {
    reRunCart,
    // userLocation 
  } = useContext(CustomerInfo);
  // const [productsCount, setProductsCount] = useState(0);
  // const [merchantCount, setMerchantCount] = useState(0);
  // const [productsView, setProductsView] = useState("card");
  // const [search, setSearch] = useState("");
  const [searchMerchant, setSearchMerchant] = useState("");
  const [qtyDialog, setQtyDialog] = useState(false);
  const [locationDialog, setLocationDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [quantityOrder, setQuantityOrder] = useState(1);
  const navigate = useNavigate();
  // const [selectedImages, setSelectedImages] = useState([]);
  // const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const getAllProducts = () => {
  //   axios
  //     .get("customer/get/products")
  //     .then((response) => {
  //       const data = response.data;
  //       setProductList({ data, ready: true });
  //       // setProductsCount(data.length);
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  const getAllMerchant = () => {
    axios
      .get("customer/get/merchant")
      .then((response) => {
        const data = response.data;
        setMerchantList({ data, ready: true });
        // setMerchantCount(data.length);
      })
      .catch((error) => console.log("error", error));
  };

  const handleAddtoCart = () => {
    var formdata = new FormData();
    formdata.append("customer_id", userData.data.id);
    formdata.append("merchant_id", selectedData.merchant_id);
    formdata.append("product_id", selectedData.id);
    formdata.append("sku", selectedData.sku);
    formdata.append("product", selectedData.product);
    formdata.append("description", selectedData.description);
    formdata.append("category", selectedData.category);
    formdata.append("price", parseFloat(selectedData.price));
    formdata.append("quantity", quantityOrder);
    setIsProcess(true);
    setQtyDialog(false);
    axios
      .post("customer/create/order-to-cart", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setTimeout(() => {
            setIsProcess(false);
            setMessage("Successfully added to cart.");
            setSeverity("success");
            setTimeout(() => {
              setOpenSnackbar(true);
              reRunCart();
              setSelectedData(null);
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    // getAllProducts();
    getAllMerchant();
  }, []);

  // const searchable = productList.data.filter((data) => {
  //   return data.product.toLowerCase().indexOf(search.trim()) !== -1;
  // });

  const searchableMerchant = merchantList.data.filter((data) => {
    return (
      data.business_name.toLowerCase().indexOf(searchMerchant.trim()) !== -1
    );
  });

  return (
    <Container>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            maxWidth: "1200px",
            paddingX: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: 1,
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* <Box flexGrow={1}>{merchantCount} merchant(s) found</Box> */}
            <Box flexGrow={1} />
            <Box>
              <TextField
                label="Search merchant/shop"
                variant="outlined"
                value={searchMerchant}
                onChange={(e) => setSearchMerchant(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2}>
              {merchantList.ready ?
                (searchableMerchant.length > 0 ? (
                  searchableMerchant.map((data, index) => (
                    <Grid
                      key={index}
                      sx={{ mt: 2 }}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                    >
                      <Card sx={{ maxWidth: "100%" }}>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {data.business_name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {/* {data.business_address} */}
                            {data.street}, {data.barangay}, {data.city}
                          </Typography>
                        </CardContent>

                        <CardActions
                          sx={{
                            // border: "1px solid #AEAEAE",
                            backgroundColor: "#00D1D1",
                            borderRadius: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(`/customer/merchant/${data.user_id}`);
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 700,
                              fontSize: 12,
                            }}
                          >
                            VIEW PRODUCTS
                          </Typography>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Box sx={{ width: "100%", mt: 2, mb: 2 }}>
                    <Typography
                      align="center"
                      sx={{
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      No merchant registered
                    </Typography>
                  </Box>
                ))
                :
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", width: "100%", height: 100, justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              }
              <Grid item xs={12}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                  <Box flexGrow={1} />
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 25]}
                    component="div"
                    count={searchableMerchant.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            maxWidth: "1200px",
            paddingX: "8px",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: 1,
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box flexGrow={1}>{productsCount} product(s) found</Box>
            <Box sx={{ mr: 1 }}>View:</Box>
            <Box>
              <IconButton
                onClick={() => {
                  setProductsView("list");
                }}
              >
                <FormatListBulletedIcon
                  sx={{
                    color: productsView === "list" ? "#d65d46" : "none",
                  }}
                />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setProductsView("card");
                }}
              >
                <AppsIcon
                  sx={{
                    color: productsView === "card" ? "#d65d46" : "none",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box flexGrow={1} />
            <Box>
              <TextField
                label="Search product"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2}>
              {productList.ready ?
                (searchable.length > 0 ? (
                  searchable.map((data, index) =>
                    productsView === "list" ? (
                      <Grid item xs={12} sm={12} md={6} key={index}>
                        <Box sx={{
                          mt: 1,
                          borderTop: "1px solid #AEAEAE",
                          borderLeft: "1px solid #AEAEAE",
                          borderBottom: "1px solid #AEAEAE",
                          borderRight: parseInt(data.status) === 1 ? "4px solid green" : "4px solid red",
                          borderRadius: 2,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          padding: 1
                        }}>
                          <CardMedia
                            component="img"
                            image={`${process.env.REACT_APP_API_IMAGE}merchant/products/${data.product_image}`}
                            height="100"
                            alt=""
                            sx={{ cursor: "pointer", width: 100 }}
                            onClick={() => {
                              let dataArr = data.product_image.split(",");
                              const res = dataArr.filter(
                                (item) =>
                                  item.includes(".jpg") ||
                                  item.includes(".png") ||
                                  item.includes(".jpeg")
                              );
                              setSelectedImages(
                                res.map(
                                  (y) =>
                                    `${process.env.REACT_APP_API_IMAGE}merchant/products/` +
                                    y
                                )
                              );
                              setIsViewerOpen(true);
                            }}
                          />
                          <Box sx={{ ml: 1 }}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                            >
                              <b>{data.product}</b>
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="caption"
                              component="div"
                            >
                              {data.description}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="caption"
                              component="div"
                            >
                              <b>{`₱${data.price.toString(2)}`}</b>
                            </Typography>
                          </Box>
                          <Box flexGrow={1} />
                          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: 120 }}>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: 700,
                                fontSize: 12,
                                backgroundColor: "#50C878",
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                padding: 1
                              }}
                              onClick={() => {
                                if (
                                  userLocation.data.latitude === undefined &&
                                  userLocation.data.longitude === undefined
                                ) {
                                  setLocationDialog(true);
                                } else {
                                  setQtyDialog(true);
                                  setSelectedData(data);
                                }
                              }}
                            >
                              ADD TO CART
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid
                        key={index}
                        sx={{ mt: 2 }}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                      >
                        <Card sx={{ maxWidth: "100%" }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={`${process.env.REACT_APP_API_IMAGE}merchant/products/${data.product_image}`}
                            alt=""
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              let dataArr = data.product_image.split(",");
                              const res = dataArr.filter(
                                (item) =>
                                  item.includes(".jpg") ||
                                  item.includes(".png") ||
                                  item.includes(".jpeg")
                              );
                              setSelectedImages(
                                res.map(
                                  (y) =>
                                    `${process.env.REACT_APP_API_IMAGE}merchant/products/` +
                                    y
                                )
                              );
                              setIsViewerOpen(true);
                            }}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {data.product}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {data.description}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              &#8369;{data.price}
                            </Typography>
                          </CardContent>

                          <CardActions
                            sx={{
                              // border: "1px solid #AEAEAE",
                              backgroundColor: "#50C878",
                              borderRadius: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (
                                userLocation.data.latitude === undefined &&
                                userLocation.data.longitude === undefined
                              ) {
                                setLocationDialog(true);
                              } else {
                                setQtyDialog(true);
                                setSelectedData(data);
                              }
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: 700,
                                fontSize: 12,
                              }}
                            >
                              ADD TO CART
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  )
                ) : (
                  <Box sx={{ width: "100%", mt: 2 }}>
                    <Typography
                      align="center"
                      sx={{
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      No products added
                    </Typography>
                  </Box>
                ))
                :
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", width: "100%", height: 100, justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              }
            </Grid>
          </Box>
        </Box> */}
      </Box>

      {/* {isViewerOpen && (
        <ImageViewer
          src={selectedImages}
          currentIndex={0}
          onClose={() => setIsViewerOpen(false)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )} */}

      <Dialog
        open={locationDialog}
        onClose={() => {
          setLocationDialog(false);
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 700 }}>
              Set location first to add products on your cart
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: 2,
                fontSize: 12,
                paddingX: 2,
                backgroundColor: "#d65d46",
              }}
              fullWidth
              onClick={() => navigate("/profile")}
            >
              Go To Profile
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={qtyDialog}
        onClose={() => {
          setQtyDialog(false);
          setSelectedData(null);
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 700 }}>Order Quantity</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <IconButton
              sx={{ mr: 4 }}
              onClick={() => {
                if (parseInt(quantityOrder) === 1) {
                  console.log("Can't add to cart with quantity of 0.");
                } else {
                  setQuantityOrder(parseInt(quantityOrder) - 1);
                }
              }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <span style={{ fontWeight: 700 }}>{quantityOrder}</span>
            <IconButton
              sx={{ ml: 4 }}
              onClick={() => {
                setQuantityOrder(parseInt(quantityOrder) + 1);
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: 2,
                fontSize: 12,
                paddingX: 2,
                backgroundColor: "#d65d46",
                width: "150px",
              }}
              onClick={handleAddtoCart}
              disabled={isProcess}
            >
              Add to cart
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
};

export default CustomerPage;
