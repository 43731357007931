import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function GoogleCallback() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const getFetchData = () => {
    fetch(
      `${process.env.REACT_APP_API}google/auth/callback${location.search}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        localStorage.setItem("estherToken", data.access_token);
        setTimeout(() => {
          navigate("/");
          navigate(0);
        }, 500);
      });
  }


  useEffect(() => {
    getFetchData()
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <DisplayLoading />;
  }
}

function DisplayLoading() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      Connecting...
    </div>
  );
}

export default GoogleCallback;
