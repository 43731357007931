import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { UserInfo } from "../../context";

const CustomerList = ({ allowAccount, module }) => {
  const [userList, setUserList] = useState({ data: [], ready: false });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [detailsDialog, setDetailsDialog] = useState({
    open: false,
    data: null,
  });
  const [verifyDialog, setVerifyDialog] = useState({ open: false, data: null });
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCustomerList = () => {
    var params = { module: "customer" };
    axios
      .get("user/admin/get/users", { params })
      .then((response) => {
        const data = response.data;
        setUserList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const handleVerifyAccount = () => {
    var formdata = new FormData();
    formdata.append("id", verifyDialog.data && verifyDialog.data.id);
    setIsProcess(true);
    axios
      .post("user/admin/update/account-status", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setTimeout(() => {
            setIsProcess(false);
            setMessage("Successfully verify the account.");
            setSeverity("success");
            setVerifyDialog({ ...verifyDialog, open: false });
            getCustomerList();
            setTimeout(() => {
              setOpenSnackbar(true);
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Email</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {userList.ready && userList.data.length > 0 ? (
              userList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <span
                      style={{ cursor: "pointer", color: "#54B4D3" }}
                      onClick={() =>
                        setDetailsDialog({ ...detailsDialog, open: true, data })
                      }
                    >
                      {data.usercustomerinfo.firstname}{" "}
                      {data.usercustomerinfo.lastname}
                    </span>
                  </TableCell>
                  <TableCell>{data.email}</TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {parseInt(data.status) === 1 ? (
                        <Tooltip title="Verified">
                          <VerifiedIcon sx={{ color: "green" }} />
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Verify Account">
                            <IconButton
                              onClick={() => {
                                setVerifyDialog({ open: true, data });
                              }}
                              disabled={
                                module === "admin" &&
                                parseInt(allowAccount.allow) === 0
                                  ? true
                                  : false
                              }
                            >
                              <VerifiedIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={4} style={{ color: "red" }}>
                  No customer found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={userList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={detailsDialog.open}
        fullWidth
        maxWidth="sm"
        onClose={() =>
          setDetailsDialog({ ...detailsDialog, open: false, data: null })
        }
      >
        {detailsDialog.data !== null && (
          <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box flexGrow={1}>
                <Typography variant="h6">
                  {detailsDialog.data.usercustomerinfo.firstname}{" "}
                  {detailsDialog.data.usercustomerinfo.lastname} Information
                </Typography>
              </Box>
              <IconButton
                onClick={() =>
                  setDetailsDialog({
                    ...detailsDialog,
                    open: false,
                    data: null,
                  })
                }
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Email"
                      fullWidth
                      size="small"
                      name="email"
                      value={
                        detailsDialog.data.email !== null
                          ? detailsDialog.data.email
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Firstname"
                      fullWidth
                      size="small"
                      name="firstname"
                      value={
                        detailsDialog.data.usercustomerinfo.firstname !== null
                          ? detailsDialog.data.usercustomerinfo.firstname
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Lastname"
                      fullWidth
                      size="small"
                      name="lastname"
                      value={
                        detailsDialog.data.usercustomerinfo.lastname !== null
                          ? detailsDialog.data.usercustomerinfo.lastname
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Contact Number"
                      fullWidth
                      size="small"
                      name="contact_no"
                      value={
                        detailsDialog.data.usercustomerinfo.contact_no !== null
                          ? detailsDialog.data.usercustomerinfo.contact_no
                          : ""
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Dialog>

      <Dialog
        open={verifyDialog.open}
        fullWidth
        maxWidth="xs"
        onClose={() => setVerifyDialog({ ...verifyDialog, open: false })}
      >
        <Box sx={{ p: 3 }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box>
                <Typography>
                  Are you sure to verify this account with a <br />
                  name of{" "}
                  {verifyDialog.data &&
                    verifyDialog.data.usercustomerinfo.lastname}
                  ,{" "}
                  {verifyDialog.data &&
                    verifyDialog.data.usercustomerinfo.firstname}
                  ?
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      backgroundColor: "#d65d46",
                      width: "40%",
                    }}
                    onClick={handleVerifyAccount}
                    disabled={isProcess}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      backgroundColor: "#AEAEAE",
                      width: "40%",
                      ml: 2,
                    }}
                    onClick={() =>
                      setVerifyDialog({ ...verifyDialog, open: false })
                    }
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
};

export default CustomerList;
