import { Check, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { UserInfo } from "../../context";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { useState } from "react";

const VouchersToPay = () => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    React.useContext(UserInfo);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [dateFrom, setDateFrom] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(new Date());
  const [voucherToPayList, setVoucherToPayList] = useState({
    data: [],
    ready: false,
  });

  const getVoucherToPayList = () => {
    axios
      .get("user/admin/get/voucher-to-pay")
      .then((response) => {
        const data = response.data;
        setVoucherToPayList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateConfirm = (code, payment_status) => {
    var formdata = new FormData();
    formdata.append("code", code);
    formdata.append("payment_status", payment_status);
    axios
      .post("user/admin/update/voucher-to-pay/complete", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getVoucherToPayList();
          setIsProcess(false);
          setMessage("Successfully updated voucher to pay.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
          }, 500);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  const handleFilter = () => {
    var params = { module: "admin", date_from: dateFrom, date_to: dateTo };
    var error = [];
    if (dateFrom > dateTo) {
      error = "error";
      setIsProcess(false);
      setMessage("Invalid inputed dates.");
      setSeverity("error");
      setTimeout(() => {
        setOpenSnackbar(true);
      }, 500);
    }
    if (error.length > 0) {
      console.log("Something went wrong.");
    } else {
      setIsProcess(true);
      axios
        .get("user/admin/get/voucher-to-pay-filter", { params })
        .then((response) => {
          const data = response.data;
          setOpenFilter(false);
          setIsProcess(false);
          setMessage("Successfully filter record.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
            setVoucherToPayList({ data, ready: true });
          }, 500);
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("Error: ", error);
        });
    }
  };

  useEffect(() => {
    getVoucherToPayList();
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex" }}>
        <Box flexGrow={1} />
        <Box>
          <Tooltip title="Filter">
            <IconButton
              onClick={() => {
                setOpenFilter(true);
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Contact Number</b>
              </TableCell>
              <TableCell align="center">
                <b>Code</b>
              </TableCell>
              <TableCell align="center">
                <b>Amount</b>
              </TableCell>
              <TableCell align="center">
                <b>Status</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {voucherToPayList.ready ? (
              voucherToPayList.data.length > 0 ? (
                voucherToPayList.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.user_id}</TableCell>
                    <TableCell>
                      {data.voucherpaymentrider.firstname}{" "}
                      {data.voucherpaymentrider.lastname}
                    </TableCell>
                    <TableCell align="right">
                      {data.voucherpaymentrider.contact_no}
                    </TableCell>
                    <TableCell>{data.code}</TableCell>
                    <TableCell align="right">{data.to_pay}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color:
                          data.payment_status === "pending" ? "red" : "green",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {data.payment_status}
                    </TableCell>
                    <TableCell align="center">
                      {data.payment_status === "pending" && (
                        <Tooltip title="Confirm">
                          <IconButton
                            onClick={() =>
                              handleUpdateConfirm(data.code, "confirmation")
                            }
                            disabled={isProcess}
                          >
                            <Check sx={{ color: "green" }} />
                          </IconButton>
                        </Tooltip>
                      )}

                      {data.payment_status !== "pending" && (
                        <Tooltip title="Complete">
                          <IconButton
                            onClick={() =>
                              handleUpdateConfirm(data.code, "complete")
                            }
                            disabled={
                              isProcess || data.payment_status === "complete"
                            }
                          >
                            <DoneAllIcon
                              sx={{
                                color:
                                  data.payment_status === "complete"
                                    ? "#AEAEAE"
                                    : "green",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={7}
                    style={{ color: "red" }}
                  >
                    No voucher to pay found!
                  </TableCell>
                </TableRow>
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={voucherToPayList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Filter Record</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setOpenFilter(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <DatePicker
              // disableFuture
              label="Date From"
              openTo="year"
              views={["year", "month", "day"]}
              value={dateFrom}
              onChange={(newValue) => {
                setDateFrom(newValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <DatePicker
              // disableFuture
              label="Date To"
              openTo="year"
              views={["year", "month", "day"]}
              value={dateTo}
              onChange={(newValue) => {
                setDateTo(newValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>

          <Box sx={{ display: "flex", mt: 2 }}>
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#1976d2",
                }}
                onClick={handleFilter}
                disabled={isProcess}
              >
                Filter
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default VouchersToPay;
