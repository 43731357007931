import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import GoogleCallback from "../components/google/GoogleCallback";
// import RiderPage from "../components/rider";
import {
  // Bento,
  ManageAccounts,
} from "@mui/icons-material";
import RiderConfirmDocu from "../components/rider/confirmdocuments";
import RiderAppbar from "../layouts/RiderAppbar";
import { useEffect } from "react";
import RiderProfile from "../components/rider/profile";
const RiderRoute = ({ module }) => {

  const redirect = localStorage.getItem("estherRedirect")

  const routeArray = [
    {
      name: "Profile",
      path: "/profile",
      icon: <ManageAccounts fontSize="small" />,
    }
  ];

  useEffect(() => {
    if (redirect !== null) {
      localStorage.removeItem("estherRedirect")
    }
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <RiderAppbar
        module={module}
        route={routeArray}
      />
      <Routes>
        <Route hidden path="/" element={<Navigate to={redirect !== null ? redirect : "/profile"} />} />
        {/* <Route hidden path="/rider" element={<RiderPage />} /> */}
        <Route hidden path="/profile" element={<RiderProfile />} />
        <Route hidden path="/rider/confirm-documents" element={<RiderConfirmDocu />} />
        <Route path="/auth/google" element={<GoogleCallback />} />
      </Routes>
    </React.Fragment>
  );
};

export default RiderRoute;
