import {
  // Badge,
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { UserInfo } from "../../context";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import emailjs from "@emailjs/browser";
// import { Info } from '@mui/icons-material';
import ImageViewer from "react-simple-image-viewer";
import { Close } from "@mui/icons-material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import provinces from "./../../../assets/province.json";
import cities from "./../../../assets/city.json";
import barangays from "./../../../assets/barangay.json";

const MerchantList = ({ allowAccount, module }) => {
  const [userList, setUserList] = useState({ data: [], ready: false });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [verifyDialog, setVerifyDialog] = useState({ open: false, data: null });
  const [detailsDialog, setDetailsDialog] = useState({
    open: false,
    data: null,
  });
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const form = useRef();

  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMerchantList = () => {
    var params = { module: "merchant" };
    axios
      .get("user/admin/get/users", { params })
      .then((response) => {
        const data = response.data;
        setUserList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  const handleVerifyAccount = () => {
    var formdata = new FormData();
    formdata.append("id", verifyDialog.data && verifyDialog.data.id);
    setIsProcess(true);
    axios
      .post("user/admin/update/account-status", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setTimeout(() => {
            setIsProcess(false);
            setMessage("Successfully verify the account.");
            setSeverity("success");
            setVerifyDialog({ ...verifyDialog, open: false });
            getMerchantList();
            setTimeout(() => {
              setOpenSnackbar(true);
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_r5wcw1v",
        "template_n30wg5m",
        form.current,
        "BCGRtcKyS6DJyXOqp"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setIsProcess(false);
            setMessage("Successfully send link to the account.");
            setSeverity("success");
            setTimeout(() => {
              setOpenSnackbar(true);
            }, 500);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    getMerchantList();
  }, []);

  const getBarangayByCode = (code) => {
    const filtered = barangays.filter((brgy) => brgy.brgy_code === code);
    return filtered[0].brgy_name;
  };

  const getCityByCode = (code) => {
    const filtered = cities.filter((city) => city.city_code === code);
    return filtered[0].city_name;
  };

  const getProvinceByCode = (code) => {
    const filtered = provinces.filter((prov) => prov.province_code === code);
    return filtered[0].province_name;
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Business Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Business Address</b>
              </TableCell>
              <TableCell align="center">
                <b>Reg. Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Reg. Contact</b>
              </TableCell>
              <TableCell align="center">
                <b>Collection</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.ready && userList.data.length > 0 ? (
              userList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    {/* {index + 1} */}
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {parseInt(data.merchant_status) === 1 ? (
                        <Tooltip title="Available">
                          <ToggleOnIcon sx={{ color: "green" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unavailable">
                          <ToggleOffIcon sx={{ color: "red" }} />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <span
                      style={{ cursor: "pointer", color: "#54B4D3" }}
                      onClick={() =>
                        setDetailsDialog({ ...detailsDialog, open: true, data })
                      }
                    >
                      {data.business_name}
                    </span>
                  </TableCell>
                  <TableCell>
                    {data.street}, {getBarangayByCode(data.barangay)},{" "}
                    {getCityByCode(data.city)},{" "}
                    {getProvinceByCode(data.province)}
                  </TableCell>
                  <TableCell>
                    {data.reg_firstname} {data.reg_lastname}
                  </TableCell>
                  <TableCell>{data.reg_contact_no}</TableCell>
                  <TableCell align="right">
                    &#8369;
                    {(
                      parseFloat(data.total_quantity) *
                        parseFloat(data.merchant_per_item) -
                      parseFloat(data.total_merchant_pay)
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {parseInt(data.status) === 1 ? (
                        <Tooltip title="Verified">
                          <VerifiedIcon sx={{ color: "green" }} />
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Verify Account">
                            <IconButton
                              onClick={() => {
                                setVerifyDialog({ open: true, data });
                              }}
                              disabled={
                                module === "admin" &&
                                parseInt(allowAccount.allow) === 0
                                  ? true
                                  : false
                              }
                            >
                              <VerifiedIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Send link to Email">
                            <form ref={form} onSubmit={handleSendEmail}>
                              <input
                                style={{ display: "none" }}
                                readOnly
                                type="text"
                                name="user_name"
                                value={`${data.reg_firstname} ${data.reg_lastname}`}
                              />
                              <input
                                style={{ display: "none" }}
                                readOnly
                                type="email"
                                name="user_email"
                                value={data.email}
                              />
                              <input
                                style={{ display: "none" }}
                                readOnly
                                type="text"
                                name="from_name"
                                value={`Esther Customer Service`}
                              />
                              <textarea
                                style={{ display: "none" }}
                                readOnly
                                name="message"
                                value={`${process.env.REACT_APP_BASEURL}?link=/merchant/confirm-documents?id=${data.id}`}
                              />
                              <IconButton
                                type="submit"
                                disabled={
                                  module === "admin" &&
                                  parseInt(allowAccount.allow) === 0
                                    ? true
                                    : false
                                }
                              >
                                <ForwardToInboxIcon />
                              </IconButton>
                            </form>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} style={{ color: "red" }}>
                  No merchant found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={userList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={verifyDialog.open}
        fullWidth
        maxWidth="xs"
        onClose={() => setVerifyDialog({ ...verifyDialog, open: false })}
      >
        <Box sx={{ p: 3 }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box>
                <Typography>
                  Are you sure to verify this account with a business <br />
                  name of {verifyDialog.data && verifyDialog.data.business_name}
                  ?
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      backgroundColor: "#d65d46",
                      width: "40%",
                    }}
                    onClick={handleVerifyAccount}
                    disabled={isProcess}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      backgroundColor: "#AEAEAE",
                      width: "40%",
                      ml: 2,
                    }}
                    onClick={() =>
                      setVerifyDialog({ ...verifyDialog, open: false })
                    }
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Dialog
        open={detailsDialog.open}
        fullWidth
        maxWidth="sm"
        onClose={() =>
          setDetailsDialog({ ...detailsDialog, open: false, data: null })
        }
      >
        {detailsDialog.data !== null && (
          <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box flexGrow={1}>
                <Typography variant="h6">
                  {detailsDialog.data.business_name} Information
                </Typography>
              </Box>
              <IconButton
                onClick={() =>
                  setDetailsDialog({
                    ...detailsDialog,
                    open: false,
                    data: null,
                  })
                }
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Email"
                      fullWidth
                      size="small"
                      name="email"
                      value={
                        detailsDialog.data.email !== null
                          ? detailsDialog.data.email
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Business Name"
                      fullWidth
                      size="small"
                      name="business_name"
                      value={
                        detailsDialog.data.business_name !== null
                          ? detailsDialog.data.business_name
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Business Category"
                      fullWidth
                      size="small"
                      name="business_category"
                      value={
                        detailsDialog.data.business_category !== null
                          ? detailsDialog.data.business_category
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Business Type"
                      fullWidth
                      size="small"
                      name="business_type"
                      value={
                        detailsDialog.data.business_type !== null
                          ? detailsDialog.data.business_type
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Per Item"
                      fullWidth
                      size="small"
                      name="merchant_per_item"
                      value={
                        detailsDialog.data.merchant_per_item !== null
                          ? detailsDialog.data.merchant_per_item
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Registered Firstname"
                      fullWidth
                      size="small"
                      name="reg_firstname"
                      value={
                        detailsDialog.data.reg_firstname !== null
                          ? detailsDialog.data.reg_firstname
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Registered Lastname"
                      fullWidth
                      size="small"
                      name="reg_lastname"
                      value={
                        detailsDialog.data.reg_lastname !== null
                          ? detailsDialog.data.reg_lastname
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Registered Contact No."
                      fullWidth
                      size="small"
                      name="reg_contact_no"
                      value={
                        detailsDialog.data.reg_contact_no !== null
                          ? detailsDialog.data.reg_contact_no
                          : ""
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address"
                      fullWidth
                      size="small"
                      name="address"
                      value={`${
                        detailsDialog.data.street !== null
                          ? detailsDialog.data.street
                          : ""
                      }, ${
                        detailsDialog.data.barangay !== null
                          ? detailsDialog.data.barangay
                          : ""
                      }, ${
                        detailsDialog.data.city !== null
                          ? detailsDialog.data.city
                          : ""
                      }`}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">
                {detailsDialog.data.business_name} Attachments
              </Typography>
            </Box>

            {detailsDialog.data.valid_id !== null && (
              <>
                <Box sx={{ mt: 3 }}>
                  <CardMedia
                    component="img"
                    image={`${process.env.REACT_APP_API_IMAGE} merchant /validids/${detailsDialog.data.valid_id}`}
                    height="100"
                    alt=""
                    sx={{ cursor: "pointer", width: 100 }}
                    onClick={() => {
                      setSelectedImages(
                        `${process.env.REACT_APP_API_IMAGE}merchant/validids/` +
                          detailsDialog.data.valid_id
                      );
                      setIsViewerOpen(true);
                      setDetailsDialog({ ...detailsDialog, open: false });
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="caption">
                    {detailsDialog.data.valid_id}
                  </Typography>
                </Box>
              </>
            )}
            {detailsDialog.data.permit !== null && (
              <>
                <Box sx={{ mt: 3 }}>
                  <CardMedia
                    component="img"
                    image={`${process.env.REACT_APP_API_IMAGE}merchant/permits/${detailsDialog.data.permit}`}
                    height="100"
                    alt=""
                    sx={{ cursor: "pointer", width: 100 }}
                    onClick={() => {
                      setSelectedImages(
                        `${process.env.REACT_APP_API_IMAGE}merchant/permits/${detailsDialog.data.permit}`
                      );
                      setIsViewerOpen(true);
                      setDetailsDialog({ ...detailsDialog, open: false });
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="caption">
                    {detailsDialog.data.permit}
                  </Typography>
                </Box>
              </>
            )}
            {detailsDialog.data.menu !== null && (
              <>
                <Box sx={{ mt: 3 }}>
                  <CardMedia
                    component="img"
                    image={`${process.env.REACT_APP_API_IMAGE}merchant/menu/${detailsDialog.data.menu}`}
                    height="100"
                    alt=""
                    sx={{ cursor: "pointer", width: 100 }}
                    onClick={() => {
                      setSelectedImages(
                        `${process.env.REACT_APP_API_IMAGE}merchant/menu/${detailsDialog.data.menu}`
                      );
                      setIsViewerOpen(true);
                      setDetailsDialog({ ...detailsDialog, open: false });
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="caption">
                    {detailsDialog.data.menu}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
      </Dialog>

      {isViewerOpen && (
        <ImageViewer
          src={[selectedImages]}
          currentIndex={0}
          onClose={() => {
            setIsViewerOpen(false);
            setDetailsDialog({ ...detailsDialog, open: true });
          }}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Box>
  );
};

export default MerchantList;
