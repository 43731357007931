import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";

const UserDeleteAccount = ({ userInfo }) => {
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Divider light />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography color="blue">
            You can delete your account and personal data associated with it.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <Button sx={{ color: "red", fontWeight: 700 }}>
            DELETE MY ACCOUNT
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserDeleteAccount;
