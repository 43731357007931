import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Button, CardMedia, CssBaseline, Dialog } from "@mui/material";
import LoginToUse from "./login/LoginToUse";
import LoginUsingEmail from "./login/LoginUsingEmail";
import SignUpEmail from "./login/SignUpEmail";
import { useEffect } from "react";
// import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

// const img1 = require("./../assets/socialmediaicon/esther.png");
const img1 = require("./../assets/socialmediaicon/logo.png");

const GuestAppbar = () => {
  // const origin = window.location.origin;
  // const pathname = window.location.pathname;
  // const url = `${origin}${pathname}`;
  // const hideFromUrl = `${process.env.REACT_APP_BASEURL}`;

  const url = window.location.search;
  const urlClient = new URLSearchParams(url).get("link");

  const [openDialogLoginMethod, setOpenDialogLoginMethod] =
    React.useState(false);
  const [openDialogLogin, setOpenDialogLogin] = React.useState(
    urlClient !== null ? true : false
  );
  const [openDialogSignup, setOpenDialogSignup] = React.useState(false);
  const [merchantTab, setMerchantTab] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (urlClient !== null) {
      localStorage.setItem("estherRedirect", urlClient);
    }
  }, [urlClient]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar
        // elevation={1}
        elevation={0}
        position="relative"
        sx={{
          // position: "sticky",
          // top: 0,
          backgroundColor: "#ff9f1d",
          color: "#000",
          minHeight: "64px",
          zIndex: 3,
        }}
      >
        <Container maxWidth="lg" style={{ height: "100%" }}>
          <Toolbar disableGutters style={{ height: "100%" }}>
            <CardMedia
              component="img"
              src={`${img1}`}
              alt=""
              sx={{ cursor: "pointer", width: 90 }}
              onClick={() => {
                window.location.href = "/";
              }}
            />
            {/* {!isMobile && (
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  ml: 1,
                  display: "flex",
                  fontSize: 24,
                  color: "#fff",
                  textDecoration: "none",
                }}
              >
                Hatood
              </Typography>
            )} */}
            <Box sx={{ flexGrow: 1 }} />
            {/* <Box
              sx={{
                // display: url === hideFromUrl ? "flex" : "none",
                alignItems: "center",
                mr: 1,
              }}
            >
              <Button
                sx={{
                  border: "2px solid #fff",
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: 700,
                  width: 80,
                }}
                // onClick={() => {
                //   setOpenDialogLoginMethod(true);
                // }}
                onClick={() => {
                  setOpenDialogLogin(true);
                }}
                size="small"
              >
                Sign in
              </Button>
            </Box> */}
            <Box
              sx={{
                // display: url === hideFromUrl ? "flex" : "none",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  border: "2px solid #fff",
                  color: "#fff",
                  textTransform: "none",
                  // fontWeight: 700,
                  // width: 160,
                }}
                onClick={() => {
                  // setOpenDialogSignup(true);
                  navigate("/sign-up");
                }}
                size="small"
              >
                Sign up
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* login method */}
      <Dialog
        open={openDialogLoginMethod}
        fullScreen
        onClose={() => {
          setOpenDialogLoginMethod(false);
        }}
      >
        <Box sx={{ heigh: "100vh", width: "100vw" }}>
          <LoginToUse
            close={() => setOpenDialogLoginMethod(false)}
            open={() => setOpenDialogLogin(true)}
          />
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "64px",
              backgroundColor: "#131418",
              bottom: 0,
            }}
          >
            <Typography sx={{ color: "#fff", fontSize: 14 }}>
              &copy; 2022 esther. All rights reserved.
            </Typography>
          </Box> */}
        </Box>
      </Dialog>

      {/* login */}
      <Dialog
        open={openDialogLogin}
        fullScreen
        onClose={() => {
          setOpenDialogLogin(false);
        }}
      >
        <Box sx={{ heigh: "100vh", width: "100vw" }}>
          <LoginUsingEmail
            close={() => setOpenDialogLogin(false)}
            tab="landingpage"
          />
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "64px",
              backgroundColor: "#131418",
              bottom: 0,
            }}
          >
            <Typography sx={{ color: "#fff", fontSize: 14 }}>
              &copy; 2022 esther. All rights reserved.
            </Typography>
          </Box> */}
        </Box>
      </Dialog>

      {/* sign up */}
      <Dialog
        open={openDialogSignup}
        fullScreen
        onClose={() => {
          setOpenDialogSignup(false);
        }}
      >
        <Box sx={{ heigh: "100vh", width: "100vw" }}>
          <SignUpEmail
            close={() => setOpenDialogSignup(false)}
            tab="integration"
            setMerchantTab={setMerchantTab}
            merchantTab={merchantTab}
          />
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "64px",
              backgroundColor: "#131418",
              bottom: 0,
            }}
          >
            <Typography sx={{ color: "#fff", fontSize: 14 }}>
              &copy; 2022 esther. All rights reserved.
            </Typography>
          </Box> */}
        </Box>
      </Dialog>
    </Box>
  );
};
export default GuestAppbar;
