import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminPage from "../components/admin";
import GoogleCallback from "../components/google/GoogleCallback";
// import AdminAppbar from "../layouts/AdminAppbar";

const AdminRoute = () => {
  return (
    <React.Fragment>
      {/* <AdminAppbar /> */}
      <Routes>
        <Route hidden path="/" element={<Navigate to="/admin" />} />
        <Route hidden path="/admin" element={<AdminPage />} />
        <Route path="/auth/google" element={<GoogleCallback />} />
      </Routes>
    </React.Fragment>
  );
};

export default AdminRoute;
