import { Close } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import StoreIcon from "@mui/icons-material/Store";
import Tables from "../tabpanel/Tables";
import axios from "axios";
import Managers from "../tabpanel/Managers";
import Cashiers from "../tabpanel/Cashiers";

const BranchDetails = ({ selectedData, close, getBranchList }) => {
  const [value, setValue] = useState("manager");
  const [merchantInfo, setMerchantInfo] = useState({ data: [], ready: false });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getMerchantInfo = useCallback(async () => {
    var params = { user_id: selectedData !== null && selectedData.merchant_id };
    await axios
      .get("user/merchant/get/info", { params })
      .then((response) => {
        const data = response.data;
        setMerchantInfo({ data, ready: true });
      })
      .catch((err) => console.log("err", err));
  }, [selectedData]);

  useEffect(() => {
    getMerchantInfo();
  }, [getMerchantInfo]);

  return (
    <>
      {selectedData !== null ? (
        <>
          <Box
            component={Paper}
            elevation={1}
            sx={{
              padding: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box flexGrow={1}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Branch Details
                </Typography>
              </Box>
              <Box>
                <Tooltip title="Close">
                  <span>
                    <IconButton
                      onClick={() => {
                        close();
                      }}
                    >
                      <Close sx={{ color: "red" }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 1,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100px",
                        justifyContent: "center",
                        mr: 2,
                      }}
                    >
                      <StoreIcon sx={{ width: "100px", height: " 100px" }} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Business:</span>{" "}
                        {merchantInfo.ready && merchantInfo.data.business_name}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
                        {merchantInfo.ready && merchantInfo.data.street},{" "}
                        {merchantInfo.ready && merchantInfo.data.barangay},{" "}
                        {merchantInfo.ready && merchantInfo.data.city}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Contact:</span>{" "}
                        {merchantInfo.ready && merchantInfo.data.reg_contact_no}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <TabContext value={value}>
            <Box
              component={Paper}
              elevation={1}
              sx={{
                // padding: 1,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // padding: 1
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  //  centered
                >
                  <Tab
                    label="Manager"
                    sx={{ fontWeight: "bold" }}
                    value={"manager"}
                  />
                  <Tab
                    label="Cashier"
                    sx={{ fontWeight: "bold" }}
                    value={"cashier"}
                  />
                  <Tab
                    label="Report(next)"
                    sx={{ fontWeight: "bold" }}
                    value={"report"}
                    disabled
                  />
                  <Tab
                    label="Tables"
                    sx={{ fontWeight: "bold" }}
                    value={"tables"}
                  />
                </Tabs>
              </Box>
            </Box>

            <Box
              component={Paper}
              elevation={1}
              sx={{
                mt: 2,
                padding: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 1,
                }}
              >
                <TabPanel value={"manager"} sx={{ padding: 0 }}>
                  <Managers data={merchantInfo.ready && merchantInfo.data} />
                </TabPanel>
                <TabPanel value={"cashier"} sx={{ padding: 0 }}>
                  <Cashiers selectedData={selectedData} />
                </TabPanel>
                <TabPanel value={"report"} sx={{ padding: 0 }}>
                  report
                </TabPanel>
                <TabPanel value={"tables"} sx={{ padding: 0 }}>
                  <Tables
                    getBranchList={() => getBranchList()}
                    selectedData={selectedData}
                  />
                </TabPanel>
              </Box>
            </Box>
          </TabContext>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            border: "1px solid #e5e5e5",
            borderStyle: "dashed",
          }}
        >
          <Typography variant="h5" sx={{ color: "red" }}>
            Selected Any Branch To View.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default BranchDetails;
