import { AttachFile } from "@mui/icons-material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";

const MerchantConfirmPayments = () => {
  const [paymentList, setPaymentList] = useState({ data: [], ready: false });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMerchantConfirmPayments = () => {
    axios
      .get("user/admin/get/merchant/payments-confirm")
      .then((response) => {
        const data = response.data;
        setPaymentList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    getMerchantConfirmPayments();
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Date confirmed</b>
              </TableCell>
              <TableCell align="center">
                <b>Collection ID</b>
              </TableCell>
              <TableCell align="center">
                <b>Business Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Business Address</b>
              </TableCell>
              <TableCell align="center">
                <b>Payment Category</b>
              </TableCell>
              <TableCell align="center">
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentList.ready && paymentList.data.length > 0 ? (
              paymentList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell align="right">
                    {moment(data.updated_at).format("MMMM DD, YYYY")}
                  </TableCell>
                  <TableCell align="right">
                    {data.cp_id.replace("c-", "")}
                  </TableCell>
                  <TableCell>
                    {data.collectionmerchantinfo.business_name}
                  </TableCell>
                  <TableCell>
                    {data.collectionmerchantinfo.street},{" "}
                    {data.collectionmerchantinfo.barangay},{" "}
                    {data.collectionmerchantinfo.city}
                  </TableCell>
                  <TableCell>
                    {data.payment_category === "online"
                      ? `${data.payment_category}(GCash)`
                      : `${data.payment_category}(Pick-up)`}
                  </TableCell>
                  <TableCell align="right">
                    &#8369;
                    {parseFloat(data.amount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      {data.payment_category === "online" && (
                        <Tooltip title="View Attachment">
                          <AttachFile
                            sx={{ cursor: "pointer", mr: 1 }}
                            onClick={() => {
                              let dataArr = data.payment_attachment.split(",");
                              const res = dataArr.filter(
                                (item) =>
                                  item.includes(".jpg") ||
                                  item.includes(".png") ||
                                  item.includes(".jpeg") ||
                                  item.includes(".JPG") ||
                                  item.includes(".PNG") ||
                                  item.includes(".JPEG")
                              );
                              setSelectedImages(
                                res.map(
                                  (y) =>
                                    `${process.env.REACT_APP_API_IMAGE}merchant/payments/` +
                                    y
                                )
                              );
                              setIsViewerOpen(true);
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} style={{ color: "red" }}>
                  No confirmed merchant payment found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={paymentList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {isViewerOpen && (
        <ImageViewer
          src={selectedImages}
          currentIndex={0}
          onClose={() => setIsViewerOpen(false)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Box>
  );
};

export default MerchantConfirmPayments;
