import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import axios from "axios";
import { UserInfo } from "../../context";

const RiderInfoForm = ({ userData }) => {
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = useContext(UserInfo);
    const [inputedFname, setInputedFname] = useState("");
    const [inputedLname, setInputedLname] = useState("");
    const [inputedMobile, setInputedMobile] = useState("");
    const [inputedAddress, setInputedAddress] = useState("")
    const [riderInfo, setRiderInfo] = useState({ data: null, ready: false });

    const handleUpdate = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);
        formdata.set("user_id", userData.data.id);
        setIsProcess(true);
        axios
            .post("rider/update/info", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    getRiderDetails();
                    setIsProcess(false);
                    setMessage("Successfully update your info.");
                    setSeverity("success");
                    setTimeout(() => {
                        setOpenSnackbar(true);
                    }, 500);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    };

    const getRiderDetails = () => {
        var params = { user_id: userData.data.id };
        axios
            .get("rider/get/info", { params })
            .then((response) => {
                const data = response.data;
                if (data.length > 0) {
                    setRiderInfo({ data, ready: true });
                    setInputedFname(data[0].firstname)
                    setInputedLname(data[0].lastname)
                    setInputedMobile(data[0].contact_no)
                    setInputedAddress(data[0].address)
                }
                else {
                    setRiderInfo({ data: null, ready: true });
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getRiderDetails()
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {riderInfo.ready && (
                <form onSubmit={handleUpdate}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: 700 }}> My Profile</Typography>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                        <Divider light />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Email Address"
                            fullWidth
                            size="small"
                            name="email"
                            value={userData.data.email !== null ? userData.data.email : ""}
                            required
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="First name"
                            fullWidth
                            size="small"
                            name="firstname"
                            defaultValue={
                                riderInfo.data !== null ? inputedFname : ""
                            }
                            required
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Last name"
                            fullWidth
                            size="small"
                            name="lastname"
                            defaultValue={
                                riderInfo.data !== null ? inputedLname : ""
                            }
                            required
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Mobile Number"
                            fullWidth
                            size="small"
                            name="contact_no"
                            defaultValue={
                                riderInfo.data !== null ? inputedMobile : ""
                            }
                            required
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Address"
                            fullWidth
                            size="small"
                            name="address"
                            defaultValue={
                                riderInfo.data !== null ? inputedAddress : ""
                            }
                            required
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Box flexGrow={1} />
                        <Box>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    textTransform: "capitalize",
                                    borderRadius: 2,
                                    fontSize: 12,
                                    paddingX: 2,
                                    backgroundColor: "#d65d46",
                                }}
                                type="submit"
                                disabled={isProcess}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            )}
        </>
    );
};

export default RiderInfoForm;
