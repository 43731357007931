import { useState } from "react";
import { useEffect } from "react";
import useSound from "use-sound";
import beep from "./notification2.mp3";

export default function Sound({ id }) {
    const [playOff, { stop }] = useSound(beep);
    const [oldId, setOldId] = useState(id, { forceSoundEnabled: true })

    useEffect(() => {
        if (oldId !== id && id > 0) {
            playOff();
            setOldId(id)
        } else {
            stop()
        }

        // eslint-disable-next-line
    }, [id, playOff]);

    return null;
}
