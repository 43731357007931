import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Edit, LocationOn } from "@mui/icons-material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { CustomerInfo, UserInfo } from "../../context";
import { useNavigate } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getPreciseDistance } from "geolib";

const CartOrder = () => {
  const {
    setIsProcess,
    isProcess,
    setMessage,
    setSeverity,
    setOpenSnackbar,
    userData
  } = useContext(UserInfo);
  const {
    reRunCart,
    cartOrder,
    subtotal,
    // userInfo,
    reRunCheckout,
    userLocation,
  } = useContext(CustomerInfo);
  const [qtyDialog, setQtyDialog] = useState(false);
  const [dateTime, setDateTime] = useState(
    new Date(new Date().getTime() + 25 * 60 * 1000)
  );
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const [quantityOrder, setQuantityOrder] = useState(0);
  const navigate = useNavigate();

  const handleEditQty = () => {
    var formdata = new FormData();
    formdata.append("cart_id", selectedData?.cart_id);
    formdata.append("quantity", parseInt(quantityOrder));
    setIsProcess(true);
    setQtyDialog(false);
    axios
      .post("customer/update/order-to-cart", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setIsProcess(false);
          setMessage("Successfully added to cart.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
            reRunCart();
            setSelectedData(null);
          }, 500);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  const handleToCheckout = () => {
    var formdata = new FormData();

    formdata.append("delivery_fee", parseFloat(deliveryFee));
    formdata.append("customer_id", userData.data.id);

    setIsProcess(true);
    axios
      .post("customer/create/order-to-checkout", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setIsProcess(false);
          setMessage("Successfully added to checkout.");
          setSeverity("success");
          setTimeout(() => {
            reRunCheckout();
            setOpenSnackbar(true);
            reRunCart();
          }, 500);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  const getDistanceMerchantAndCustomer = () => {
    var dis = getPreciseDistance(
      {
        latitude: cartOrder.data[0].merchantLat,
        longitude: cartOrder.data[0].merchantLng,
      },
      {
        latitude: userLocation.data.latitude,
        longitude: userLocation.data.longitude,
      }
    );

    var km = parseFloat(dis) / 1000;

    if (km >= 0 && km <= 2) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE));
    }
    else if (km > 2 && km <= 3) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 10);
    }
    else if (km > 3 && km <= 4) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 20);
    }
    else if (km > 4 && km <= 5) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 30);
    }
    else if (km > 10 && km <= 6) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 40);
    }
    else if (km > 6 && km <= 7) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 50);
    }
    else if (km > 7 && km <= 8) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 60);
    }
    else if (km > 8 && km <= 9) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 70);
    }
    else if (km > 9 && km <= 10) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 80);
    }
    else if (km > 10 && km <= 11) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 90);
    }
    else if (km > 11 && km <= 12) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 100);
    }
    else if (km > 12 && km <= 13) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 110);
    }
    else if (km > 13 && km <= 14) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 120);
    }
    else if (km > 14 && km <= 15) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 130);
    }
    else if (km > 15 && km <= 16) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 140);
    }
    else if (km > 16 && km <= 17) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 150);
    }
    else if (km > 17 && km <= 18) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 160);
    }
    else if (km > 18 && km <= 19) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 170);
    }
    else if (km > 19 && km <= 20) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 180);
    }
    else if (km > 20 && km <= 21) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 190);
    }
    else if (km > 21 && km <= 22) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 200);
    }
    else if (km > 22 && km <= 23) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 210);
    }
    else if (km > 23 && km <= 24) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 220);
    }
    else if (km > 24 && km <= 25) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 230);
    }
    else if (km > 25 && km <= 26) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 240);
    }
    else if (km > 26 && km <= 27) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 250);
    }
    else if (km > 27 && km <= 28) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 260);
    }
    else if (km > 28 && km <= 29) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 270);
    }
    else if (km > 29 && km <= 30) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 280);
    }
    else if (km > 30 && km <= 31) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 290);
    }
    else if (km > 31 && km <= 32) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 300);
    }
    else if (km > 32 && km <= 33) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 310);
    }
    else if (km > 33 && km <= 34) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 320);
    }
    else if (km > 34 && km <= 35) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 330);
    }
    else if (km > 35 && km <= 36) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 340);
    }
    else if (km > 36 && km <= 37) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 350);
    }
    else if (km > 37 && km <= 38) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 360);
    }
    else if (km > 38 && km <= 39) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 370);
    }
    else if (km > 39 && km <= 40) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 380);
    }
    else if (km > 40 && km <= 41) {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 390);
    }
    else {
      setDeliveryFee(parseFloat(process.env.REACT_APP_DELIVERY_BASE) + 400);
    }
  };

  useEffect(() => {
    if (cartOrder.ready && cartOrder.data.length > 0) {
      getDistanceMerchantAndCustomer();
    }
    // eslint-disable-next-line
  }, [cartOrder]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 700 }}
        >
          Merchant
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5">{cartOrder.data[0].business_name}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
        }}
      >
        <Typography variant="subtitle2">
          {/* <span style={{ fontWeight: 700 }}>Address:</span>{" "} */}
          {/* {cartOrder.data[0].business_address} */}
          {cartOrder.data[0].street}, {cartOrder.data[0].barangay}, {cartOrder.data[0].city}
        </Typography>
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          My location
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >

        <Box sx={{ width: "100%", }}>
          <Box sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            width: "100%",
            border: "2px solid #50C878",
            borderStyle: "dashed",
            borderRadius: 2,
            padding: 1,
            marginBottom: 1
          }}>
            <Box sx={{ display: "flex" }}>
              <LocationOn
                sx={{
                  mr: 2,
                  color: "#50C878",
                }}
                fontSize="small"
              />
              <Typography>
                {userLocation.data.address}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          mb: 2,
        }}
      >
        <Typography
          onClick={() => navigate(`/profile`)}
          sx={{
            cursor: "pointer",
            color: "blue",
            width: "110px",
            fontWeight: 700,
          }}
          variant="caption"
        >
          Change Location
        </Typography>
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Estimated delivery time
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          mb: 2,
        }}
      >
        <TimePicker
          renderInput={(params) => <TextField {...params} />}
          value={dateTime}
          label="Delivery time"
          onChange={(newValue) => {
            setDateTime(newValue);
          }}
          minTime={new Date(new Date().getTime() + 20 * 60 * 1000)}
          maxTime={new Date(0, 0, 0, 23, 59)}
        />
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          My Order(s)
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {cartOrder.ready &&
          (cartOrder.data.length > 0 ? (
            cartOrder.data.map((data, index) => (
              <Box
                key={index}
                sx={{
                  mt: 1,
                  borderTop: "1px solid #AEAEAE",
                  borderLeft: "1px solid #AEAEAE",
                  borderBottom: "1px solid #AEAEAE",
                  borderRight: "4px solid green",
                  borderRadius: 2,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: 1
                }}
              >
                <CardMedia
                  component="img"
                  image={`${process.env.REACT_APP_API_IMAGE}merchant/products/${data.prodimg}`}
                  alt=""
                  style={{ width: '100px' }}
                />
                <Box sx={{ ml: 1 }}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    <b>{data.product}</b>
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                  >
                    {data.description}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                  >
                    <b>{`₱${data.price.toString(2)}`}</b>
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: 100 }}>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      {`Qty: ${parseInt(data.quantity)}`}
                    </Typography>
                  </Box>

                  <Edit
                    onClick={() => {
                      setQtyDialog(true);
                      setSelectedData(data);
                      setQuantityOrder(data.quantity);
                    }}
                    sx={{
                      ml: 2,
                      cursor: "pointer",
                      color: "#1976d2",
                      fontSize: '20px'
                    }}
                    fontSize="small"
                  />
                </Box>
              </Box>
            ))
          ) : (
            <Box sx={{ width: "100%", mt: 2 }}>
              <Typography
                align="center"
                sx={{
                  color: "red",
                  textAlign: "center",
                }}
              >
                No orders added
              </Typography>
            </Box>
          ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          mb: 2,
        }}
      >
        <Typography
          onClick={() =>
            navigate(`/customer/merchant/${cartOrder.data[0].merchant_id}`)
          }
          sx={{
            cursor: "pointer",
            color: "blue",
            width: "110px",
            fontWeight: 700,
          }}
          variant="caption"
        >
          Add More Items
        </Typography>
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>Subtotal</Typography>
          </Box>
          <Box>&#8369;{`${parseFloat(subtotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>Convenience Fee</Typography>
          </Box>
          <Box>&#8369;{`${parseFloat(deliveryFee).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>Total</Typography>
          </Box>
          <Box>
            <span style={{ fontWeight: 700, fontSize: 20 }}>
              &#8369;
              {`${(parseFloat(subtotal) + parseFloat(deliveryFee)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            </span>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              borderRadius: 2,
              fontSize: 12,
              paddingX: 2,
              backgroundColor: "#d65d46",
              width: "220px",
              height: "40px",
            }}
            onClick={handleToCheckout}
            disabled={
              isProcess || (deliveryFee === 0 && cartOrder.data.length < 1)
            }
          >
            Review
          </Button>
        </Box>
      </Box>

      <Dialog
        open={qtyDialog}
        onClose={() => {
          setQtyDialog(false);
          setSelectedData(null);
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 700 }}>Edit Order Qty</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <IconButton
              sx={{ mr: 4 }}
              onClick={() => {
                if (parseInt(quantityOrder) === 0) {
                  console.log(
                    "Can't add to cart with quantity of negative number."
                  );
                } else {
                  setQuantityOrder(parseInt(quantityOrder) - 1);
                }
              }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <span style={{ fontWeight: 700 }}>{quantityOrder}</span>
            <IconButton
              sx={{ ml: 4 }}
              onClick={() => {
                setQuantityOrder(parseInt(quantityOrder) + 1);
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: 2,
                fontSize: 12,
                paddingX: 2,
                backgroundColor: "#d65d46",
                width: "150px",
              }}
              onClick={handleEditQty}
              disabled={isProcess}
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CartOrder;
