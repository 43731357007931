import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { Close, FilterList } from "@mui/icons-material";
import { UserInfo } from "../../context";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useEffect } from "react";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const VoucherList = ({ allowVoucher, module }) => {
  const {
    userData,
    setIsProcess,
    isProcess,
    setMessage,
    setSeverity,
    setOpenSnackbar,
  } = React.useContext(UserInfo);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addVoucherDialog, setAddVoucherDialog] = React.useState(false);
  const [randCode, setRandCode] = useState(null);
  const [dateExpiry, setDateExpiry] = React.useState(new Date());
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  const [voucherList, setVoucherList] = useState({ data: [], ready: false });
  const [openFilter, setOpenFilter] = React.useState(false);
  const [dateFrom, setDateFrom] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(new Date());

  const getVoucherList = () => {
    var params = { module: "admin" };
    axios
      .get("user/admin/get/voucher", { params })
      .then((response) => {
        const data = response.data;
        setVoucherList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNewVoucher = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.append("created_by", userData.data.id);
    formdata.append("expiry", moment(dateExpiry).format());
    axios
      .post("user/admin/create/new-voucher", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          getVoucherList();
          setAddVoucherDialog(false);
          setIsProcess(false);
          setMessage("Successfully added voucher.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
          }, 500);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  const randomizeCode = () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789";
    for (var i = 0; i < 8; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  };

  const handleFilter = () => {
    var params = { module: "admin", date_from: dateFrom, date_to: dateTo };
    var error = [];
    if (dateFrom > dateTo) {
      error = "error";
      setIsProcess(false);
      setMessage("Invalid inputed dates.");
      setSeverity("error");
      setTimeout(() => {
        setOpenSnackbar(true);
      }, 500);
    }
    if (error.length > 0) {
      console.log("Something went wrong.");
    } else {
      setIsProcess(true);
      axios
        .get("user/admin/get/voucher-filter", { params })
        .then((response) => {
          const data = response.data;
          setOpenFilter(false);
          setIsProcess(false);
          setMessage("Successfully filter record.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
            setVoucherList({ data, ready: true });
          }, 500);
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("Error: ", error);
        });
    }
  };

  useEffect(() => {
    getVoucherList();
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex" }}>
        <Box flexGrow={1} />
        <Box>
          <Tooltip title="Add Voucher">
            <span>
              <IconButton
                onClick={() => {
                  setAddVoucherDialog(true);
                  setRandCode(randomizeCode());
                }}
                disabled={
                  module === "admin" && parseInt(allowVoucher.allow) === 0
                    ? true
                    : false
                }
              >
                <ReceiptIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title="Filter">
            <span>
              <IconButton
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                <FilterList />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Code</b>
              </TableCell>
              <TableCell align="center">
                <b>Category</b>
              </TableCell>
              <TableCell align="center">
                <b>Status</b>
              </TableCell>
              <TableCell align="center">
                <b>Expiry Date</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {voucherList.ready ? (
              voucherList.data.length > 0 ? (
                voucherList.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{data.code}</TableCell>
                    <TableCell>
                      {data.category === "cost" ? "Order Cost" : "Delivery"} - (
                      {data.sub_category !== "full"
                        ? `₱${parseFloat(data.amount).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`
                        : "Full Amount"}
                      )
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: parseInt(data.status) === 1 ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {parseInt(data.status) === 1 ? "Available" : "Used"}
                    </TableCell>
                    <TableCell align="right">
                      {moment(data.expiry).format("MMMM DD, YYYY")}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    style={{ color: "red" }}
                  >
                    No voucher code
                  </TableCell>
                </TableRow>
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={voucherList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={addVoucherDialog}
        onClose={() => setAddVoucherDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Add Voucher</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setAddVoucherDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            <form onSubmit={handleNewVoucher}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  InputProps={{
                    disableunderline: "true",
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={randCode !== null ? randCode : ""}
                  label="Code"
                  fullWidth
                  name="code"
                  required
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <DatePicker
                  label="Date Expiry"
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={dateExpiry}
                  onChange={(newValue) => {
                    setDateExpiry(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={selectedCategory !== null ? selectedCategory : ""}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  name="category"
                >
                  <option value="">--Select Category--</option>
                  <option value="cost">Order Cost</option>
                  <option value="delivery">Delivery</option>
                </TextField>
              </Box>

              <Box
                sx={{
                  display: selectedCategory === null ? "none" : "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    selectedSubCategory !== null ? selectedSubCategory : ""
                  }
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                  name="sub_category"
                >
                  <option value="">--Select Sub Category--</option>
                  <option value="full">Full Amount</option>
                  <option value="set">Set Amount</option>
                </TextField>
              </Box>

              {selectedSubCategory === "set" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#000",
                        fontWeight: 700,
                      },
                      "& .MuiInput-input": {
                        borderRadius: "8px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                        fontSize: 14,
                      },
                      "& label + .MuiInput-root-emKjRF": {
                        marginTop: "18px",
                      },
                    }}
                    InputProps={{
                      disableunderline: "true",
                      inputProps: {
                        min: 1,
                        max: 99999999999,
                        step: 0.01,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Amount"
                    fullWidth
                    name="amount"
                    required
                    type="number"
                  />
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      paddingX: 2,
                      backgroundColor: "#d65d46",
                    }}
                    type="submit"
                    disabled={isProcess}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Filter Record</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setOpenFilter(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <DatePicker
              // disableFuture
              label="Date From"
              openTo="year"
              views={["year", "month", "day"]}
              value={dateFrom}
              onChange={(newValue) => {
                setDateFrom(newValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <DatePicker
              // disableFuture
              label="Date To"
              openTo="year"
              views={["year", "month", "day"]}
              value={dateTo}
              onChange={(newValue) => {
                setDateTo(newValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Box>

          <Box sx={{ display: "flex", mt: 2 }}>
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#1976d2",
                }}
                onClick={handleFilter}
                disabled={isProcess}
              >
                Filter
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default VoucherList;
