import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AdminList from "./AdminList";
import CityList from "./CityList";

const AdminsAbout = () => {
  const [tabPanel, setTabPanel] = useState("list");
  const [userList, setUserList] = useState({ data: [], ready: false });
  const [cityList, setCityList] = useState({ data: [], ready: false });

  const handleChangeMethod = (event, newValue) => {
    setTabPanel(newValue);
  };

  const getAdminList = () => {
    var params = { module: "admin" };
    axios
      .get("user/admin/get/users", { params })
      .then((response) => {
        const data = response.data;
        setUserList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  const getCityList = () => {
    axios
      .get("user/admin/get/cities")
      .then((response) => {
        const data = response.data;
        setCityList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    getAdminList();
    getCityList();
  }, []);

  return (
    <>
      <TabContext value={tabPanel}>
        <Box
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Box>
            <TabList
              onChange={handleChangeMethod}
              aria-label="tabs"
              TabIndicatorProps={{ style: { background: "#1AE294" } }}
            >
              <Tab
                className="customtabbutton"
                label="Admin List"
                value="list"
              />

              <Tab
                className="customtabbutton"
                label="City List"
                value="city-list"
              />
            </TabList>
          </Box>

          <TabPanel value="list" className="tabpanelzeropadding">
            <AdminList
              userList={userList}
              getAdminList={() => getAdminList()}
            />
          </TabPanel>

          <TabPanel value="city-list" className="tabpanelzeropadding">
            <CityList cityList={cityList} getCityList={() => getCityList()} />
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
};

export default AdminsAbout;
